import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  useMediaQuery,
  Link,
  Menu,
  MenuItem,
  useTheme,
  IconButton,
  List,
  ListItem,
  Drawer,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import { setMenuIndex } from "../../reducers/UiReducer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  menuTitle: {},
  logOutButton: {
    background: `transparent`,
    border: "1px solid #08FCFE",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "white",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 30px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
  // sidebarWrapper: {
  //   backgroundColor: "rgba(217, 217, 217, 0.06)",
  //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
  //   backdropFilter: "blur(8px)",
  // },
}));

export default function SideBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuIndex = useSelector((state) => state.ui.menuIndex);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const handleMenuClick = (index) => {
    dispatch(setMenuIndex(index));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
      <Box
        px={2}
        pt={3}
        pb={2}
        bgcolor={"#110918"}
        display="flex"
        flexDirection="column"
        height="100%"
        minWidth={240}
        style={{ display: isSmall ? "none" : "block", position: "fixed" }}
      >
        <Box className={classes.sidebarWrapper}>
          <Box py={2}>
            <Link href="/" style={{ textDecoration: "none" }}>
              <Typography variant="body2" pb={1}>
                <img
                  src={process.env.PUBLIC_URL + "/Images/newLogo.png"}
                  height="35px"
                />
              </Typography>
            </Link>
          </Box>
          <Box pt={5}>
            <Paper
              sx={{
                boxShadow: 0,

                bgcolor: "transparent",
                py: 2,
                px: 3,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: 2,
              }}
              elevation={0}
              onClick={() => handleMenuClick(0)}
            >
              {menuIndex === 0 ? (
                <img
                  src={process.env.PUBLIC_URL + "/Images/borrowNew.png"}
                  height="27px"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/Images/icon1White.png"}
                  height="27px"
                />
              )}

              <Typography
                variant="title1"
                className={`${menuIndex === 0 ? "maxButton" : ""}`}
                style={{
                  marginLeft: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "30px",
                  color: menuIndex === 0 ? "#08FCFE" : "white",
                }}
              >
                Borrow
              </Typography>
            </Paper>
            <Paper
              sx={{
                boxShadow: 0,

                bgcolor: "transparent",
                py: 2,
                px: 3,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: 2,
              }}
              elevation={0}
              onClick={() => handleMenuClick(1)}
              // style={{ background: menuIndex === 1 ? "#08FCFE" : "transparent" }}
            >
              {menuIndex === 1 ? (
                <img
                  src={process.env.PUBLIC_URL + "/Images/farmNew.png"}
                  height="27px"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/Images/farmIconSide.png"}
                  height="27px"
                />
              )}

              <Typography
                variant="title1"
                className={`${menuIndex === 1 ? "maxButton" : ""}`}
                style={{
                  marginLeft: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "30px",
                  color: menuIndex === 1 ? "#08FCFE" : "white",
                }}
              >
                Farm
              </Typography>
            </Paper>
            <Paper
              sx={{
                boxShadow: 0,

                bgcolor: "transparent",
                py: 2,
                px: 3,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: 2,
              }}
              elevation={0}
              onClick={() => handleMenuClick(2)}
              // style={{ background: menuIndex === 2 ? "#08FCFE" : "transparent" }}
            >
              {menuIndex === 2 ? (
                <img
                  src={process.env.PUBLIC_URL + "/Images/stakeNew.png"}
                  height="27px"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/Images/stakeIconSide.png"}
                  height="27px"
                />
              )}

              <Typography
                variant="title1"
                className={`${menuIndex === 2 ? "maxButton" : ""}`}
                style={{
                  marginLeft: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "30px",
                  color: menuIndex === 2 ? "#08FCFE" : "white",
                }}
              >
                Stake
              </Typography>
            </Paper>
            <Paper
              sx={{
                boxShadow: 0,

                bgcolor: "transparent",
                py: 2,
                px: 3,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: 2,
              }}
              elevation={0}
              onClick={() => handleMenuClick(3)}
              // style={{ background: menuIndex === 3 ? "#08FCFE" : "transparent" }}
            >
              {menuIndex === 3 ? (
                <img
                  src={process.env.PUBLIC_URL + "/Images/infoNew.png"}
                  height="27px"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/Images/infoIconSide.png"}
                  height="27px"
                />
              )}

              <Typography
                variant="title1"
                className={`${menuIndex === 3 ? "maxButton" : ""}`}
                style={{
                  marginLeft: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "30px",
                  color: menuIndex === 3 ? "#08FCFE" : "white",
                }}
              >
                Info
              </Typography>
            </Paper>
            <Paper
              sx={{
                boxShadow: 0,
                bgcolor: "transparent",
                py: 2,
                px: 3,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: 2,
              }}
              elevation={0}
              // onClick={() => {
              //   handleMenuClick(4);
              //   handleMenuClick(5);
              //   handleMenuClick(6);
              //   handleMenuClick(7);
              // }}
            >
              {menuIndex === 4 ||
              menuIndex === 5 ||
              menuIndex === 6 ||
              menuIndex === 7 ||
              menuIndex === 8 ? (
                <img
                  src={process.env.PUBLIC_URL + "/Images/moreNew.png"}
                  height="20px"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/Images/moreIconSide.png"}
                  height="10px"
                />
              )}

              <Typography
                variant="title1"
                className={`${
                  menuIndex === 4 ||
                  menuIndex === 5 ||
                  menuIndex === 6 ||
                  menuIndex === 7 ||
                  menuIndex === 8
                    ? "maxButton"
                    : ""
                }`}
                style={{
                  color:
                    menuIndex === 4 ||
                    menuIndex === 5 ||
                    menuIndex === 6 ||
                    menuIndex === 7 ||
                    menuIndex === 8
                      ? "#08FCFE"
                      : "white",
                  marginLeft: 10,
                }}
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
              >
                More
              </Typography>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
                PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    border: "1px solid grey",
                    borderRadius: 8,
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleMenuClick(4);
                  }}
                  className={`${menuIndex === 4 ? "maxButton" : ""}`}
                  style={{
                    color: menuIndex === 4 ? "#08FCFE" : "white",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Swap
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleMenuClick(5);
                  }}
                  className={`${menuIndex === 5 ? "maxButton" : ""}`}
                  style={{
                    color: menuIndex === 5 ? "#08FCFE" : "white",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Calculator
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleMenuClick(6);
                  }}
                  className={`${menuIndex === 6 ? "maxButton" : ""}`}
                  style={{
                    color: menuIndex === 6 ? "#08FCFE" : "white",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Strategies
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleMenuClick(7);
                  }}
                  className={`${menuIndex === 7 ? "maxButton" : ""}`}
                  style={{
                    color: menuIndex === 7 ? "#08FCFE" : "white",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  History
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleMenuClick(8);
                  }}
                  className={`${menuIndex === 8 ? "maxButton" : ""}`}
                  style={{
                    color: menuIndex === 8 ? "#08FCFE" : "white",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Redeem
                </MenuItem>
              </Menu>
            </Paper>
          </Box>
          {/* <Box style={{ marginTop: 230 }}>
          <Link href="/" style={{ textDecoration: "none" }}>
            <Paper
              key={0}
              sx={{
                boxShadow: 0,
                bgcolor: "transparent",
                py: 2,
                px: 3,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <button className={classes.logOutButton}>Logout</button>
            </Paper>
          </Link>
        </Box> */}
        </Box>
      </Box>
      <div className="mobile-navbar-dapp">
        <div className="d-flex justify-content-start m-2">
          <img
            src={process.env.PUBLIC_URL + "/Images/newLogo.png"}
            alt=""
            style={{ height: 30 }}
          />
        </div>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          sx={{
            position: "absolute",
            top: 0,
            right: 20,
            margin: "0.8rem",
            zIndex: 1,
            color: "white",
          }}
        >
          {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>

        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          PaperProps={{ sx: { backgroundColor: "#020202" } }}
          sx={{ zIndex: 2 }}
        >
          <List sx={{ width: "70vw", maxWidth: "280px" }}>
            <ListItem>
              <Box pt={5}>
                <Paper
                  sx={{
                    boxShadow: 0,

                    bgcolor: "transparent",
                    py: 2,
                    px: 3,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: 2,
                  }}
                  elevation={0}
                  onClick={() => handleMenuClick(0)}
                >
                  {menuIndex === 0 ? (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/borrowNew.png"}
                      height="27px"
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/icon1White.png"}
                      height="27px"
                    />
                  )}

                  <Typography
                    variant="title1"
                    className={`${menuIndex === 0 ? "maxButton" : ""}`}
                    style={{
                      marginLeft: 10,
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "30px",
                      color: menuIndex === 0 ? "#08FCFE" : "white",
                    }}
                  >
                    Borrow
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    boxShadow: 0,

                    bgcolor: "transparent",
                    py: 2,
                    px: 3,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: 2,
                  }}
                  elevation={0}
                  onClick={() => handleMenuClick(1)}
                  // style={{ background: menuIndex === 1 ? "#08FCFE" : "transparent" }}
                >
                  {menuIndex === 1 ? (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/farmNew.png"}
                      height="27px"
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/farmIconSide.png"}
                      height="27px"
                    />
                  )}

                  <Typography
                    variant="title1"
                    className={`${menuIndex === 1 ? "maxButton" : ""}`}
                    style={{
                      marginLeft: 10,
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "30px",
                      color: menuIndex === 1 ? "#08FCFE" : "white",
                    }}
                  >
                    Farm
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    boxShadow: 0,

                    bgcolor: "transparent",
                    py: 2,
                    px: 3,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: 2,
                  }}
                  elevation={0}
                  onClick={() => handleMenuClick(2)}
                  // style={{ background: menuIndex === 2 ? "#08FCFE" : "transparent" }}
                >
                  {menuIndex === 2 ? (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/stakeNew.png"}
                      height="27px"
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/stakeIconSide.png"}
                      height="27px"
                    />
                  )}

                  <Typography
                    variant="title1"
                    className={`${menuIndex === 2 ? "maxButton" : ""}`}
                    style={{
                      marginLeft: 10,
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "30px",
                      color: menuIndex === 2 ? "#08FCFE" : "white",
                    }}
                  >
                    Stake
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    boxShadow: 0,

                    bgcolor: "transparent",
                    py: 2,
                    px: 3,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: 2,
                  }}
                  elevation={0}
                  onClick={() => handleMenuClick(3)}
                  // style={{ background: menuIndex === 3 ? "#08FCFE" : "transparent" }}
                >
                  {menuIndex === 3 ? (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/infoNew.png"}
                      height="27px"
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/infoIconSide.png"}
                      height="27px"
                    />
                  )}

                  <Typography
                    variant="title1"
                    className={`${menuIndex === 3 ? "maxButton" : ""}`}
                    style={{
                      marginLeft: 10,
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "30px",
                      color: menuIndex === 3 ? "#08FCFE" : "white",
                    }}
                  >
                    Info
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    boxShadow: 0,
                    bgcolor: "transparent",
                    py: 2,
                    px: 3,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: 2,
                  }}
                  elevation={0}
                  // onClick={() => {
                  //   handleMenuClick(4);
                  //   handleMenuClick(5);
                  //   handleMenuClick(6);
                  //   handleMenuClick(7);
                  // }}
                >
                  {menuIndex === 4 ||
                  menuIndex === 5 ||
                  menuIndex === 6 ||
                  menuIndex === 7 ||
                  menuIndex === 8 ? (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/moreNew.png"}
                      height="20px"
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/Images/moreIconSide.png"}
                      height="10px"
                    />
                  )}

                  <Typography
                    variant="title1"
                    className={`${
                      menuIndex === 4 ||
                      menuIndex === 5 ||
                      menuIndex === 6 ||
                      menuIndex === 7 ||
                      menuIndex === 8
                        ? "maxButton"
                        : ""
                    }`}
                    style={{
                      color:
                        menuIndex === 4 ||
                        menuIndex === 5 ||
                        menuIndex === 6 ||
                        menuIndex === 7 ||
                        menuIndex === 8
                          ? "#08FCFE"
                          : "white",
                      marginLeft: 10,
                    }}
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    onMouseOver={handleClick}
                  >
                    More
                  </Typography>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                    PaperProps={{
                      style: {
                        backgroundColor: "transparent",
                        border: "1px solid grey",
                        borderRadius: 8,
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleMenuClick(4);
                      }}
                      className={`${menuIndex === 4 ? "maxButton" : ""}`}
                      style={{
                        color: menuIndex === 4 ? "#08FCFE" : "white",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      Swap
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleMenuClick(5);
                      }}
                      className={`${menuIndex === 5 ? "maxButton" : ""}`}
                      style={{
                        color: menuIndex === 5 ? "#08FCFE" : "white",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      Calculator
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleMenuClick(6);
                      }}
                      className={`${menuIndex === 6 ? "maxButton" : ""}`}
                      style={{
                        color: menuIndex === 6 ? "#08FCFE" : "white",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      Strategies
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleMenuClick(7);
                      }}
                      className={`${menuIndex === 7 ? "maxButton" : ""}`}
                      style={{
                        color: menuIndex === 7 ? "#08FCFE" : "white",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      History
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleMenuClick(8);
                      }}
                      className={`${menuIndex === 8 ? "maxButton" : ""}`}
                      style={{
                        color: menuIndex === 8 ? "#08FCFE" : "white",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      Redeem
                    </MenuItem>
                  </Menu>
                </Paper>
              </Box>
            </ListItem>

            <Link
              href="/landing/coming-soon"
              style={{ textDecoration: "none" }}
            ></Link>
          </List>
        </Drawer>
      </div>
    </>
  );
}
