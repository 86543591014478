import { Link } from "@mui/material";
import React, { useState } from "react";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const footerWrapperStyles = {
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
    backgroundColor: "#12071C",
    padding: "25px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  };

  const logoStyles = {
    height: isMobile ? 25 : 40,
    width: "auto",
    cursor: "pointer",
  };

  const iconStyles = {
    height: isMobile ? 15 : 25,
    width: "auto",
    marginRight: isMobile ? 10 : 20,
    cursor: "pointer",
    // marginTop: isMobile ? 15 : 0,
  };

  const iconWrapperStyles = {
    display: "flex",
    alignItems: "center",
    marginTop: isMobile ? "20px" : "0px",
  };

  const buttonStyles = {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "black",
    cursor: "pointer",
    fontSize: isMobile ? "10px" : "16px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: isMobile ? "5px 20px" : "7px 30px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 576);
  };

  window.addEventListener("resize", handleResize);

  return (
    <div style={footerWrapperStyles}>
      <div>
        <Link href="/landing" style={{ textDecoration: "none" }}>
          <img
            src={process.env.PUBLIC_URL + "/Images/newLogo.png"}
            alt=""
            style={logoStyles}
          />
        </Link>
      </div>
      <div
      // style={
      //   isMobile
      //     ? { flexDirection: "row", alignItems: "center" }
      //     : iconWrapperStyles
      // }
      >
        {/* <img
          src={process.env.PUBLIC_URL + "/Images/email.png"}
          alt="Email"
          style={iconStyles}
        />
        <img
          src={process.env.PUBLIC_URL + "/Images/facebook.png"}
          alt="Facebook"
          style={iconStyles}
        />
        <img
          src={process.env.PUBLIC_URL + "/Images/instagram.png"}
          alt="Instagram"
          style={iconStyles}
        /> */}
        <a target="_blank" href="https://discord.com/invite/ujKEVCEvmf">
          <img
            src={
              "https://www.pngkey.com/png/full/20-200938_white-discord-logo-png-png-free-discord-logo.png"
            }
            alt="dsicord"
            style={iconStyles}
          />
        </a>
        <a target="_blank" href="https://twitter.com/peegibank">
          <img
            src={process.env.PUBLIC_URL + "/Images/twitter.png"}
            alt="Twitter"
            style={iconStyles}
          />
        </a>

        <button style={buttonStyles}>Enter App</button>

        <div
        // style={
        //   isMobile
        //     ? { marginTop: "20px", display: "flex", alignItems: "flex-start" }
        //     : {}
        // }
        ></div>
      </div>
    </div>
  );
};

export default Footer;
