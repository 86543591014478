import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import { tokenList } from "../../utils/data";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "100%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    color: "white",
    textAlign: "left",
    marginTop: 10,
  },
  valueToken1: {
    color: "white",
    marginTop: 10,
  },
  description: {
    fontWeight: 600,
    color: "white",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#757C88",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
  minButton: {
    border: "1px solid #6B6E7D",
    borderRadius: 7,
    color: "white",
    cursor: "pointr",
    backgroundColor: "rgba(106, 85, 234,0.03)",
  },
  buttonFirst: {
    width: "100%",
    color: "#212121",
    backgroundColor: "#eeeeee",
    padding: "7px 20px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  buttonSecond: {
    width: "100%",
    color: "white",
    backgroundColor: "#6A55EA",
    padding: "7px 20px",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  buttonWrapper: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "auto",
    width: "100%",
    border: "1px solid #545464",
    borderRadius: 8,
  },
  buttonCard: {
    border: "1px solid #08FCFE",
    borderRadius: 10,
    color: "white",
    backgroundColor: "transparent",
    width: "100%",
    padding: 5,
    fontSize: 14,
    fontFamily: 500,
  },
}));

export default function SwapCard() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [orderType, setOrderType] = useState("stake");
  const [expandTokens, setExpandTokens] = useState(false);
  const [selectedToken, setSelectedToken] = useState(tokenList[0]);
  const [tokenPriceData, setTokenPriceData] = useState(null);
  const navigate = useNavigate();

  const handleTokenSelect = (index) => {
    setSelectedToken(tokenList[index]);
    setExpandTokens(false);
  };

  return (
    <Box pt={0} className={classes.card}>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ paddingTop: 30, paddingBottom: 20 }}
      >
        <Typography
          className={classes.title}
          style={{
            fontSize: isSmall ? 16 : 20,
            fontWeight: 500,
          }}
        >
          Pay
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <div
            style={{
              borderRadius: 14,
              background: "transparent",
              border: "1px solid #6B6E7D",
              padding: isSmall ? "2px 8px 2px 8px" : "3px 10px 3px 10px",
              color: "white",
              width: "fit-content",
              marginLeft: 7,
              fontWeight: 600,
              fontSize: isSmall ? 11 : 14,
              cursor: "pointer",
            }}
          >
            MIN
          </div>
          <div
            style={{
              borderRadius: 14,
              background: "transparent",
              border: "1px solid #6B6E7D",
              padding: isSmall ? "2px 8px 2px 8px" : "3px 10px 3px 10px",
              color: "white",
              width: "fit-content",
              marginLeft: 7,
              fontWeight: 600,
              fontSize: isSmall ? 11 : 14,
              cursor: "pointer",
            }}
          >
            HALF
          </div>
          <div
            style={{
              borderRadius: 14,
              background: "transparent",
              border: "1px solid #6B6E7D",
              padding: isSmall ? "2px 8px 2px 8px" : "3px 10px 3px 10px",
              color: "white",
              width: "fit-content",
              marginLeft: 7,
              fontWeight: 600,
              fontSize: isSmall ? 11 : 14,
              cursor: "pointer",
            }}
          >
            MAX
          </div>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={8} md={6}>
            <Accordion
              expanded={expandTokens}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                border: "1px solid #2d2d32",
                // boxShadow: "none",
                borderRadius: 10,
              }}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    style={{
                      color: "#f9f9f9",
                      padding: 0,
                      height: isSmall ? 20 : "",
                    }}
                  />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
                onClick={() => setExpandTokens(!expandTokens)}
              >
                <Box
                  display="flex"
                  flexDirection={"row"}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <img
                    src={selectedToken.logoURI}
                    alt={"TokenLogo"}
                    style={{ height: isSmall ? 22 : 28 }}
                  />
                  <Box ml={1}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color={"#e5e5e5"}
                      lineHeight={1}
                      padding={0}
                    >
                      {selectedToken.symbol}{" "}
                      {tokenPriceData && (
                        <small
                          className="blink_me"
                          style={{ color: "green", fontSize: isSmall ? 8 : 11 }}
                        >
                          ${tokenPriceData.usd.toFixed(3)}
                        </small>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  style={{
                    maxHeight: 100,
                    overflowY: "auto",
                    borderTop: "0.5px solid #414141",
                  }}
                >
                  {tokenList.map((singleToken, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection={"row"}
                        justifyContent="flex-start"
                        alignItems="center"
                        py={1}
                        onClick={() => handleTokenSelect(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={singleToken.logoURI}
                          alt={"TokenLogo"}
                          height="28px"
                        />
                        <Box ml={1}>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color={"#e5e5e5"}
                            lineHeight={1}
                            noWrap
                          >
                            {singleToken.symbol}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Typography
              className={classes.value}
              style={{ fontSize: isSmall ? 13 : "" }}
            >
              Bal: 2532 USDC
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className={classes.valueToken1}
              style={{
                fontSize: isSmall ? 13 : 18,
                fontWeight: 500,
                marginTop: isSmall ? 14 : 10,
              }}
            >
              10000.00
            </Typography>
            <Typography
              className={classes.value1}
              style={{
                fontSize: isSmall ? 13 : "",
                fontWeight: 600,
                color: "#757C88",
                marginTop: 25,
                lineHeight: 1.5,
                paddingTop: 5,
              }}
            >
              1000.00
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          src={process.env.PUBLIC_URL + "/Images/swapToken.png"}
          style={{ height: isSmall ? 30 : 50 }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ paddingTop: 10, paddingBottom: 20 }}
      >
        <Typography
          className={classes.title}
          style={{
            fontSize: isSmall ? 16 : 20,
            fontWeight: 500,
          }}
        >
          Receive
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={8} md={6}>
            <Accordion
              expanded={expandTokens}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                border: "1px solid #2d2d32",
                // boxShadow: "none",
                borderRadius: 10,
              }}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    style={{
                      color: "#f9f9f9",
                      padding: 0,
                      height: isSmall ? 20 : "",
                    }}
                  />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
                onClick={() => setExpandTokens(!expandTokens)}
              >
                <Box
                  display="flex"
                  flexDirection={"row"}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <img
                    src={selectedToken.logoURI}
                    alt={"TokenLogo"}
                    style={{ height: isSmall ? 22 : 28 }}
                  />
                  <Box ml={1}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color={"#e5e5e5"}
                      lineHeight={1}
                      padding={0}
                    >
                      {selectedToken.symbol}{" "}
                      {tokenPriceData && (
                        <small
                          className="blink_me"
                          style={{ color: "green", fontSize: isSmall ? 8 : 11 }}
                        >
                          ${tokenPriceData.usd.toFixed(3)}
                        </small>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  style={{
                    maxHeight: 100,
                    overflowY: "auto",
                    borderTop: "0.5px solid #414141",
                  }}
                >
                  {tokenList.map((singleToken, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection={"row"}
                        justifyContent="flex-start"
                        alignItems="center"
                        py={1}
                        onClick={() => handleTokenSelect(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={singleToken.logoURI}
                          alt={"TokenLogo"}
                          height="28px"
                        />
                        <Box ml={1}>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color={"#e5e5e5"}
                            lineHeight={1}
                            noWrap
                          >
                            {singleToken.symbol}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Typography
              className={classes.value}
              style={{ fontSize: isSmall ? 13 : "" }}
            >
              Bal: 2532 USDC
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className={classes.valueToken1}
              style={{
                fontSize: isSmall ? 13 : 18,
                fontWeight: 500,
                marginTop: isSmall ? 14 : 10,
              }}
            >
              10000.00
            </Typography>
            <Typography
              className={classes.value1}
              style={{
                fontSize: isSmall ? 13 : "",
                fontWeight: 600,
                color: "#757C88",
                marginTop: 25,
                lineHeight: 1.5,
                paddingTop: 5,
              }}
            >
              1000.00
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className="d-flex justify-content-around mt-4">
        <Grid
          container
          style={{
            borderRadius: 10,
            background: "#272937",
            // border: "1px solid #6B6E7D",
            padding: isSmall ? "2px 12px 2px 12px" : "6px 30px 6px 30px",
            color: "white",
            width: "fit-content",
            marginLeft: 7,
            fontWeight: 600,
            fontSize: isSmall ? 10 : 14,
            cursor: "pointer",
          }}
        >
          1 USDC=0.25326 SQL
        </Grid>
        <div
          style={{
            borderRadius: 10,
            background: "#272937",
            // border: "1px solid #6B6E7D",
            padding: isSmall ? "2px 15px 2px 15px" : "6px 35px 6px 35px",
            color: "white",
            width: "fit-content",
            marginLeft: 7,
            fontWeight: 600,
            fontSize: isSmall ? 10 : 14,
            cursor: "pointer",
          }}
        >
          Slippage 1%
        </div>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Grid
          container
          mt={2}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={4} md={6}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={classes.value}
                style={{ fontSize: isSmall ? 14 : 18, fontWeight: 500 }}
              >
                Rate
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} md={6}>
            <Typography
              className={classes.title}
              style={{
                fontSize: isSmall ? 14 : 18,
                fontWeight: 500,
                marginLeft: 15,
              }}
            >
              1 UNI= 45.956 USDT
            </Typography>
          </Grid>
          <Grid item xs={8} md={6}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={classes.value}
                style={{ fontSize: isSmall ? 14 : 18, fontWeight: 500 }}
              >
                Slippage Tolerance
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={6}>
            <Typography
              className={classes.title}
              style={{
                fontSize: isSmall ? 14 : 18,
                fontWeight: 500,
                marginLeft: 15,
              }}
            >
              1.5%
            </Typography>
          </Grid>
          <Grid item xs={8} md={6}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={classes.value}
                style={{ fontSize: isSmall ? 14 : 18, fontWeight: 500 }}
              >
                Estimated Fees
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={6}>
            <Typography
              className={classes.title}
              style={{
                fontSize: isSmall ? 14 : 18,
                fontWeight: 500,
                marginLeft: 15,
              }}
            >
              $0.20308
            </Typography>
          </Grid>
          <Grid item xs={8} md={6}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={classes.value}
                style={{ fontSize: isSmall ? 14 : 18, fontWeight: 500 }}
              >
                Price Impact
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={6}>
            <Typography
              className={classes.title}
              style={{
                fontSize: isSmall ? 14 : 18,
                fontWeight: 500,
                marginLeft: 15,
                color: "#08FCFE",
              }}
            >
              2.35%
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid item md={12} my={2}>
        <Button
          className={classes.navButton}
          style={{
            width: "98%",
            color: "#000000",
            fontWeight: 600,
            borderRadius: 7,
            color: "black",
          }}
        >
          Swap Now
        </Button>
      </Grid>
    </Box>
  );
}
