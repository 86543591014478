import React from "react";
import TopBar from "./TopBar";
import TopHeader from "./TopHeader";

function Home() {
  return (
    <div
      className="home-wrapper"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Images/headerBg.png)`,
      }}
    >
      <div>
        <TopBar />
      </div>
      <div>
        <TopHeader />
      </div>
    </div>
  );
}

export default Home;
