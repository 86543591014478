import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Hidden, useMediaQuery, useTheme } from "@mui/material";
import SideBar from "../components/SideBar";
import Header from "../components/Header";
import AssetDetailComponent from "./AssetDetailComponent";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer";
import FooterCard from "./FooterCard";
import Borrows from "../components/Borrows";
import Stake from "../components/Stake";
import Info from "../components/Info";
import Swap from "../components/Swap";
import Calculator from "../components/Calculator";
import Strategies from "../components/Strategies";
import History from "../components/History";
import Redeem from "../components/Redeem";
import Farm from "../components/Farm";

const useStyles = makeStyles({
  background: {
    backgroundPosition: "center center,center center",
    backgroundRepeat: "no-repeat,no-repeat",
    backgroundSize: "cover,contain",
    backgroundColor: "#16161A",
    minHeight: "100vh",

    paddingTop: "5%",
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: "0.02em",
    color: "#ffffff",
    textAlign: "left",
  },

  para: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "0.02em",
    color: "#414141",
    textAlign: "center",
  },
});

export default function Acculumation() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const store = useSelector((state) => state);
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => setPageLoaded(true), []);

  return (
    <Box>
      {pageLoaded && (
        <Grid container>
          <Hidden mdDown>
            <Grid
              item
              md={2}
              xs={12}
              className={classes.sidebar}
              style={{ width: isSmall ? "100%" : "" }}
            >
              <SideBar />
            </Grid>
          </Hidden>
          <Grid item md={10} xs={12} style={{ backgroundColor: "black" }}>
            <Header />

            <AssetDetailComponent />
            <FooterCard />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
