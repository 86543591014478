import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
// import { useChain } from "react-moralis";
import { Container } from "@mui/system";
import PoolCard from "./PoolCard";
import FarmCard from "../common/FarmCard";
import Footer from "../../components/Footer";
import FooterCard from "../common/FooterCard";

const useStyles = makeStyles((theme) => ({
  background: {
    // backgroundImage: 'url("images/network.png")',
    backgroundPosition: "center center,center center",
    backgroundRepeat: "no-repeat,no-repeat",
    backgroundSize: "cover,contain",
    height: "100%",
    width: "100%",
    paddingTop: "2%",
    paddingLeft: "3%",
    paddingRight: "3%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "10%",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  pageTitle: {
    textAlign: "left",
  },

  pageSubtitle: {
    color: "#bdbdbd",
    textAlign: "left",
  },
}));

export default function Farms() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  let poolsData = [
    {
      title: "YUSD Stability Pool",
      src: process.env.PUBLIC_URL + "/Images/pusdIcon.png",
      description: "Earn liquidation fees & PEEGI rewards",
      tvl: "TVL",
      reward: "Rewards",
      stake: "Your Stake",
      return: "Est. weekly return",
      earned: "PEEGI Earned",
      tvlNo: "$446,764.54",
      rewardsNo: "19.983%",
      stakeNo: "0.000",
      stakeNoPool: "0.000% of pool",
      returnNo: "0",
      earnedNo: "0.000",
    },
    {
      title: "Curve LP",
      src: process.env.PUBLIC_URL + "/Images/curveIcon.png",
      description: "Earn liquidation fees ",
      tvl: "TVL",
      reward: "Rewards",
      stake: "Your Stake",
      return: "Est. weekly return",
      earned: "PEEGI Earned",
      tvlNo: "$446,764.54",
      rewardsNo: "19.983%",
      stakeNo: "0.000",
      stakeNoPool: "0.000% of pool",
      returnNo: "0",
      earnedNo: "0.000",
    },
    {
      title: "Curve LP",
      src: process.env.PUBLIC_URL + "/Images/curveIcon.png",
      description: "Earn liquidation fees ",
      tvl: "TVL",
      reward: "Rewards",
      stake: "Your Stake",
      return: "Est. weekly return",
      earned: "PEEGI Earned",
      tvlNo: "$446,764.54",
      rewardsNo: "19.983%",
      stakeNo: "0.000",
      stakeNoPool: "0.000% of pool",
      returnNo: "0",
      earnedNo: "0.000",
    },
    {
      title: "Curve LP",
      src: process.env.PUBLIC_URL + "/Images/curveIcon.png",
      description: "Earn liquidation fees ",
      tvl: "TVL",
      reward: "Rewards",
      stake: "Your Stake",
      return: "Est. weekly return",
      earned: "PEEGI Earned",
      tvlNo: "$446,764.54",
      rewardsNo: "19.983%",
      stakeNo: "0.000",
      stakeNoPool: "0.000% of pool",
      returnNo: "0",
      earnedNo: "0.000",
    },
  ];

  return (
    <Box>
      <Box
        className={classes.background}
        style={{
          backgroundColor: "black",
          width: "100%",
          height: isSmall ? "100%" : "800px",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          style={{ minHeight: "100%" }}
        >
          <Box>
            <Container>
              <Typography
                className={classes.pageTitle}
                style={{
                  fontSize: 36,
                  fontWeight: 900,
                  fontFamily: "lexend",
                  color: "white",
                  textAlign: isSmall ? "center" : "",
                }}
              >
                Farm
              </Typography>
              <Grid
                container
                display={"flex"}
                justifyContent="space-around"
                spacing={2}
              >
                {poolsData.map((singlePool, index) => (
                  <Grid item md={3} xs={12} key={index}>
                    <FarmCard poolStaticData={singlePool} index={index} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
