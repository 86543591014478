import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import Link from "next/link";
// import { useLazyQuery } from "@apollo/client";
// import {
//   GetPoolDataById,
//   GetUserDataQueryByPool,
// } from "./../../queries/graphQueries";
// import Web3 from "web3";
// import { useWeb3Auth } from "../../hooks/useWeb3Auth";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "60%",
    width: "100%",
    border: "2.31855px solid #16FFE1",
    boxShadow:
      " linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%);",
    borderRadius: 10,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100px",
      width: "220px",
      marginTop: 0,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 20,
      paddingTop: 10,
      paddingRight: 0,
    },
  },
  cardWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",

    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  title: {
    fontWeight: 500,
    color: "#757C88",
    textAlign: "left",
    fontSize: 240,
    [theme.breakpoints.down("md")]: {
      fontSize: 280,
    },
  },
  description: {
    fontWeight: 400,
    color: "#08FCFE",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#f9f9f9",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
}));

export default function PoolCard({ poolStaticData, index }) {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box pt={0} className={classes.card}>
      <Box className={classes.cardWrap}>
        <Box mt={2}>
          <Typography className={classes.title}>
            {poolStaticData.title}
          </Typography>
          <Typography className={classes.description} paragraph>
            $ {poolStaticData.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
