import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "100%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    fontWeight: 500,
    color: "#757C88",
    textAlign: "left",
    fontSize: 12,
  },
  description: {
    fontWeight: 400,
    color: "#08FCFE",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#f9f9f9",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
}));

export default function Info() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box pt={0} className={classes.card}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box mt={2}>
            <Typography
              className={classes.title}
              style={{ fontSize: isSmall ? 12 : 16, fontWeight: 600 }}
            >
              Total Value Locked
            </Typography>
          </Box>
        </Box>
        <Typography
          className={classes.description}
          style={{ fontSize: isSmall ? 16 : 22, fontWeight: 700 }}
          paragraph
        >
          $17.8462M
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{ fontSize: isSmall ? 9 : 12, fontWeight: 500 }}
          >
            PSM USDC Minted
          </Typography>
          <Typography
            className={classes.title}
            style={{ fontSize: isSmall ? 9 : 12, fontWeight: 500 }}
          >
            Curve YUSD Pool
          </Typography>
          <Typography
            className={classes.title}
            style={{ fontSize: isSmall ? 9 : 12, fontWeight: 500 }}
          >
            Platypus YUSD/USDC Pool
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography
            className={classes.title}
            style={{
              fontSize: isSmall ? 11 : 16,
              fontWeight: 600,
              color: "white",
              textAlign: "left",
            }}
          >
            $3.5256M
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: isSmall ? 11 : 16,
              fontWeight: 600,
              color: "white",
              textAlign: "left",
            }}
          >
            $3.5256M
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: isSmall ? 11 : 16,
              fontWeight: 600,
              color: "white",
              textAlign: "left",
            }}
          >
            $3.5256M
          </Typography>
        </Box>
      </Box>
    </>
  );
}
