import React, { useState } from "react";

function Powered() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 576);
  };
  const styles = {
    header: {
      color: "white",
      fontSize: isMobile ? 30 : 48,
      fontWeight: 700,
      textAlign: isMobile ? "center" : "left",
    },
    subHeading: {
      color: "white",
      fontSize: isMobile ? 14 : 18,
      fontWeight: 400,
      textAlign: isMobile ? "center" : "left",
    },
    subWrapper: {
      width: "100%",
      paddingLeft: "10%",
      paddingRight: "10%",
      marginBottom: "20px",
    },

    mobileBack: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/Images/arbitrumMob.png)`,
      backgroundPosition: "right center, center center",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundSize: "contain",
      height: "fit-content",
      width: "100%",
    },
  };
  window.addEventListener("resize", handleResize);

  return (
    <div className="px-2" style={{ marginTop: 60 }}>
      <div className="row">
        <div
          className="col-md-6 d-flex align-items-center"
          style={isMobile ? styles.mobileBack : null}
        >
          <div style={styles.subWrapper}>
            <div style={styles.header}>Powered by Arbitrum</div>
            <div style={styles.subHeading}>
              A layer 2 solution that enhances the capabilities of Ethereum’s
              smart contracts. It increases transaction speed and overall
              scalability while adding extra privacy features.
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="col-md-6">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/Images/arbitrum.png"}
                style={styles.ArbitrumImage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Powered;
