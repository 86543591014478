import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "100%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    color: "white",
    textAlign: "left",
    marginTop: 10,
  },
  description: {
    fontWeight: 600,
    color: "#757C88",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#757C88",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
  //   buttonMax: {
  //     fontSize: 72,
  //     background: "-webkit-linear-gradient(#eee, #333)",
  //     webkitBackgroundClip: "text",
  //     webkitTextFillColor: "transparent",
  //   },
  buttonFirst: {
    width: "100%",
    color: "#212121",
    backgroundColor: "#eeeeee",
    padding: "7px 20px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  buttonSecond: {
    width: "100%",
    color: "white",
    backgroundColor: "#6A55EA",
    padding: "7px 20px",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  buttonWrapper: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "auto",
    width: "100%",
    border: "1px solid #545464",
    borderRadius: 8,
  },
}));

export default function HistoryCard() {
  const classes = useStyles();
  const theme = useTheme();

  const [orderType, setOrderType] = useState("stake");
  const navigate = useNavigate();
  return (
    <Box pt={0} className={classes.card}>
      <Typography
        className={classes.title}
        style={{
          fontSize: 18,
          fontWeight: 600,
          paddingTop: 30,
          paddingBottom: 10,
        }}
      >
        Transactions
      </Typography>
      <Typography
        className={classes.description}
        style={{
          fontSize: 18,
          fontWeight: 500,
          paddingBottom: 30,
        }}
      >
        No Transactions made yet
      </Typography>
      <Grid item md={6}>
        <Box display="flex" flexDirection="row">
          <Typography
            className={classes.description}
            style={{ fontSize: 18, fontWeight: 500, marginRight: 10 }}
          >
            1W
          </Typography>
          <Typography
            className={classes.description}
            style={{ fontSize: 18, fontWeight: 500, marginRight: 10 }}
          >
            1M
          </Typography>
          <Typography
            className={classes.description}
            style={{ fontSize: 18, fontWeight: 500, marginRight: 10 }}
          >
            1Y
          </Typography>
        </Box>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Box
            display="flex"
            flexDirection="row"
            style={{ width: "100%", paddingLeft: 25 }}
          >
            <Grid item md={6}>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 30,
                }}
              >
                TVL
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Rewards
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Your stake
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Est. weekly return
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                PGB Earned
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 30,
                  textAlign: "right",
                }}
              >
                $0.0000
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                  textAlign: "right",
                }}
              >
                0.000%
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                  textAlign: "right",
                }}
              >
                0.000% of pool
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                  textAlign: "right",
                }}
              >
                0
              </Typography>
              <Typography
                className={classes.title}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: 10,
                  marginTop: 10,
                  textAlign: "right",
                }}
              >
                0.000
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}
