import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import SideBar from "./dashboardComponent/components/SideBar";
import Header from "./dashboardComponent/components/Header";
import Farm from "./dashboardComponent/components/Farm";
import Stake from "./dashboardComponent/components/Stake";
import Info from "./dashboardComponent/components/Info";
import More from "./dashboardComponent/components/More";
import Borrows from "./dashboardComponent/components/Borrows";
import FooterCard from "./dashboardComponent/common/FooterCard";
import Calculator from "./dashboardComponent/components/Calculator";
import Strategies from "./dashboardComponent/components/Strategies";
import History from "./dashboardComponent/components/History";
import Psm from "./dashboardComponent/components/Psm";
import Swap from "./dashboardComponent/components/Swap";
import Redeem from "./dashboardComponent/components/Redeem";
import { Route, Router, Routes } from "react-router-dom";
import AssetDetails from "./dashboardComponent/common/AssetDetails";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#17191A",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    border: "1px solid #414141",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 10,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const store = useSelector((state) => state);
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => setPageLoaded(true), []);

  const { menuIndex } = store.ui;

  return (
    <>
      <Box>
        {pageLoaded && (
          <Grid container>
            <Grid
              item
              md={2}
              xs={12}
              className={classes.sidebar}
              style={{ width: isSmall ? "100%" : "" }}
            >
              <SideBar />
            </Grid>
            <Grid item md={10} xs={12} style={{ backgroundColor: "black" }}>
              <Header />
              {menuIndex === 0 && <Borrows />}
              {menuIndex === 1 && <Farm />}
              {menuIndex === 2 && <Stake />}
              {menuIndex === 3 && <Info />}
              {menuIndex === 4 && <Swap />}
              {menuIndex === 5 && <Calculator />}
              {menuIndex === 6 && <Strategies />}
              {menuIndex === 7 && <History />}
              {menuIndex === 8 && <Redeem />}

              <FooterCard />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
