import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
const useStyles = makeStyles((theme) => ({
  // card: {
  //   background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
  //   marginTop: 20,
  //   marginBottom: 20,
  //   padding: "10px 10px 10px 10px",
  //   width: "98%",
  //   // border: "1px solid #414141",
  //   boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
  //   borderRadius: 12,
  //   "&:hover": {
  //     boxShadow: "0px 24px 33px -9px #0000005C",
  //   },
  //   [theme.breakpoints.down("md")]: {
  //     height: "100%",
  //     width: "100%",
  //   },
  // },
  // heading: {
  //   fontWeight: 500,
  //   fontSize: 16,
  //   letterSpacing: "0.02em",
  //   color: "#f9f9f9",
  //   fontFamily: "Inter",
  //   textAlign: "center",
  // },
  // headingMain: {
  //   fontWeight: 500,
  //   fontSize: "16px important",
  //   letterSpacing: "0.02em",
  //   color: "#f9f9f9",
  //   fontFamily: "Inter",
  //   textAlign: "center",
  // },
  // para: {
  //   fontWeight: 400,
  //   fontSize: 14,
  //   letterSpacing: "0.02em",
  //   fontFamily: "Inter",
  //   color: "#757C88",
  //   textAlign: "center",
  // },
  // imageTable: {
  //   height: 30,
  // },
}));

function TokenDesposit() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Divider sx={{ borderBottomWidth: 3 }} color="white" className="my-4" />
      <Box className="d-flex justify-content-around align-items-center mt-4 w-100 p-2">
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Box display="flex">
            <img
              src={process.env.PUBLIC_URL + "/Images/avaxNew.png"}
              style={{ width: 50, height: "auto", marginRight: 5 }}
            />{" "}
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
            >
              <Typography
                variant="body2"
                className={classes.heading}
                textAlign="left"
                fontWeight={600}
                style={{ color: "white", fontSize: 18, fontWeight: 500 }}
              >
                Wrapped Avax
              </Typography>
              <Typography
                variant="body2"
                className={classes.para}
                textAlign="left"
                fontWeight={600}
                style={{ color: "white", fontSize: 14, fontWeight: 500 }}
              >
                Wavax
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
            >
              <Typography
                variant="body2"
                className={classes.heading}
                textAlign="left"
                fontWeight={600}
                style={{ color: "white", fontSize: 20, fontWeight: 600 }}
              >
                00.00
              </Typography>
              <Typography
                variant="body2"
                className={classes.para}
                textAlign="left"
                fontWeight={600}
                style={{ color: "white", fontSize: 16, fontWeight: 600 }}
              >
                00.00
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        style={{ marginBottom: 30 }}
      >
        <Box className="d-flex justify-content-around align-items-center mt-4 w-100">
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: 10,
              background: "#272937",
              // border: "1px solid #6B6E7D",
              padding: "10px 50px 10px 50px",
              color: "white",
              width: "50%",
              marginLeft: 7,
              fontWeight: 600,
              fontSize: 18,
              cursor: "pointer",
            }}
          >
            + Deposit
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: 10,
              background: "#272937",
              // border: "1px solid #6B6E7D",
              padding: "10px 50px 10px 50px",
              color: "white",
              width: "50%",
              marginLeft: 7,
              fontWeight: 600,
              fontSize: 18,
              cursor: "pointer",
            }}
          >
            - Withdraw
          </Grid>
        </Box>
      </Grid>
    </>
  );
}

export default TokenDesposit;
