import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import StabilityDialog from "./dialogBox/StabilityDialog";
import PoolStakeDialog from "./dialogBox/PoolStakeDialog";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "100%",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    fontWeight: 500,
    color: "white",
    textAlign: "left",
    fontSize: 12,
  },
  description: {
    color: "#08FCFE",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#f9f9f9",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
  buttonCard: {
    border: "1px solid #08FCFE",
    borderRadius: 10,
    color: "white",
    backgroundColor: "transparent",
    width: "100%",
    padding: 5,
    fontSize: 14,
    fontFamily: 500,
  },
}));

export default function FarmCard({ poolStaticData, index }) {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderType, setOrderType] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenStakeDialog = (type) => {
    setOpenDialog(true);
    setOrderType(type);
  };

  const handleClickCloseStakeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box pt={0} className={classes.card}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          className="text-center"
          display="flex"
          flexDirection={"row"}
          justifyContent="center"
          alignItems="center"
        >
          <Box mt={2}>
            <Typography className={classes.title}>
              <img
                className="card-img-top"
                src={poolStaticData.src}
                alt="Card image cap"
                style={{ width: 25, marginRight: 10 }}
              />

              {poolStaticData.title}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        className={classes.description}
        paragraph
        style={{ fontSize: 11, fontWeight: 500 }}
      >
        {poolStaticData.description}
      </Typography>
      <Box>
        <Divider sx={{ borderBottomWidth: 3 }} color="white" />
      </Box>
      <Box my={4} display="flex" justifyContent="space-between">
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.tvl}
        </Typography>
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.tvlNo}
        </Typography>
      </Box>
      <Box my={4} display="flex" justifyContent="space-between">
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.reward}
        </Typography>
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.rewardsNo}
        </Typography>
      </Box>
      <Box my={4} display="flex" justifyContent="space-between">
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.stake}
        </Typography>
        <Box display="flex" flexDirection="column">
          <Typography
            style={{
              color: "white",
              fontSize: 15,
              fontWeight: 500,
              textAlign: "right",
            }}
          >
            {poolStaticData.stakeNo}
          </Typography>
          <Typography
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: 400,
              textAlign: "right",
              color: "#757C88",
            }}
          >
            {poolStaticData.stakeNoPool}
          </Typography>
        </Box>
      </Box>
      <Box my={4} display="flex" justifyContent="space-between">
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.return}
        </Typography>
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.returnNo}
        </Typography>
      </Box>
      <Box my={4} display="flex" justifyContent="space-between">
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.earned}
        </Typography>
        <Typography
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: 500,
          }}
        >
          {poolStaticData.earnedNo}
        </Typography>
      </Box>
      <Box my={4}>
        <Typography
          style={{
            textAlign: "right",
            color: "#08FCFE",
            fontSize: 15,
            fontWeight: 500,
            cursor: "pointer",
          }}
          onClick={handleClickOpen}
        >
          View More
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <button
          className={classes.buttonCard}
          onClick={() => handleClickOpenStakeDialog("stake")}
          style={{ marginRight: 10 }}
        >
          Stake
        </button>
        <button
          className={classes.buttonCard}
          onClick={() => handleClickOpenStakeDialog("unstake")}
        >
          Unstake
        </button>
      </Box>
      <StabilityDialog open={open} handleClose={handleClose} />
      <PoolStakeDialog
        openDialog={openDialog}
        handleClickCloseStakeDialog={handleClickCloseStakeDialog}
        orderType={orderType}
      />
    </Box>
  );
}
