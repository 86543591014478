import React, { useState } from "react";
// import ConnectButton from "./ConnectButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "@mui/material";

const styles = {
  logoWrapper: {
    display: "flex",
    listStyleType: "none",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 10,
    cursor: "pointer",
  },
  fontSize: {
    fontSize: 24,
  },
  logo: {
    height: 40,
    width: "auto",
  },
  navWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.06)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(8px)",
    height: 80,
  },
  linkWrapper: {
    display: "flex",
    listStyleType: "none",
    justifyContent: "space-around",
    alignItems: "center",
  },
  navLink: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: "0.02em",
    color: "#FFFFFF",
    fontFamily: "lexend",
    fontStyle: "normal",
    cursor: "pointer",
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "black",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 30px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
};

function TopBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const navLinks = [
    { text: "Docs", className: "text-white", href: "landing/coming-soon" },
    { text: "Security", className: "text-white", href: "landing/coming-soon" },
    { text: "FAQ", className: "text-white", href: "landing/coming-soon" },
  ];

  return (
    <>
      <div
        class="d-flex row justify-content-center align-items-center p-1 desktop-navbar"
        style={styles.navWrapper}
      >
        <div class="col-md-6 mt-2">
          <Link href="/landing" style={{ textDecoration: "none" }}>
            <ul style={styles.logoWrapper}>
              <li>
                <img
                  src={process.env.PUBLIC_URL + "/Images/newLogo.png"}
                  alt=""
                  style={styles.logo}
                />
              </li>
            </ul>
          </Link>
        </div>
        <div className="col-md-6 mt-2">
          <ul style={styles.linkWrapper}>
            {navLinks.map((link) => (
              <Link href={link.href} style={{ textDecoration: "none" }}>
                <li className={link.className} style={styles.navLink}>
                  {link.text}
                </li>
              </Link>
            ))}
            <Link
              href="/landing/coming-soon"
              style={{ textDecoration: "none" }}
            >
              <button style={styles.navButton}>Enter App</button>
            </Link>
          </ul>
        </div>
      </div>

      {/* Navbar drawer for mobile screens */}
      <div
        className="mobile-navbar"
        style={{ backgroundColor: "rgba(217, 217, 217, 0.07)" }}
      >
        <div className="d-flex justify-content-start m-2">
          <img
            src={process.env.PUBLIC_URL + "/Images/newLogo.png"}
            alt="new-logo"
            style={{ height: 40 }}
          />
        </div>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          sx={{
            position: "absolute",
            top: 0,
            right: 20,
            margin: "0.5rem",
            zIndex: 1,
            color: "white",
          }}
        >
          {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          PaperProps={{ sx: { backgroundColor: "#020202" } }}
          sx={{ zIndex: 2 }}
        >
          <List sx={{ width: "70vw", maxWidth: "280px" }}>
            {navLinks.map((link) => (
              <ListItem key={link.text}>
                <a
                  href="landing/coming-soon"
                  className={`nav-link ${link.className}`}
                >
                  {link.text}
                </a>
              </ListItem>
            ))}

            <Link
              href="/landing/coming-soon"
              style={{ textDecoration: "none" }}
            >
              <div className="ml-2">
                <button style={styles.navButton}>Enter App</button>
              </div>
            </Link>
          </List>
        </Drawer>
      </div>
    </>
  );
}

export default TopBar;
