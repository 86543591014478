import React from "react";
import Borrow from "./components/Borrow";
import Cards from "./components/Cards";
import Home from "./components/Home";
import PeegiCards from "./components/PeegiCards";
import Powered from "./components/Powered";
import Security from "./components/Security";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
// import gradientBg from "./assets/Images/gradientBg.jpeg";

const Landing = () => {


  return (
    <>
      <div>
        <Home />
        <div
          className="cards-wrapper"
          style={{
            // backgroundImage: `url(${gradientBg})`,
            backgroundImage: `url(${process.env.PUBLIC_URL}/Images/gradientBg.png)`,
            backgroundPosition: "center center, center center",
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundSize: "cover",
            height: "fit-content",
            width: "100%  !important",
            overflowX: "hidden",
          }}
        >
          <Cards />
          <Borrow />
          <PeegiCards />
          <Powered />
          {/* <Security />
          <Partners /> */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Landing;
