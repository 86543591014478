import React, { useState } from "react";

const Borrow = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 576);
  };

  const headerStyle = {
    color: "white",
    fontSize: isMobile ? 30 : 48,
    fontWeight: 700,
    textAlign: isMobile ? "center" : "left",
  };

  const subHeadingStyle = {
    color: "white",
    fontSize: isMobile ? 14 : 22,
    fontWeight: 400,
    textAlign: isMobile ? "center" : "left",
  };

  const subWrapperStyle = {
    width: isMobile ? "100%" : "80%",
  };

  const navButtonStyle = {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "black",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 30px",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
    textAlign: "left",
    marginTop: 15,
  };

  const styles = {
    mobileBack: {
      backgroundImage: `url("images/opacBorrowImg.png")`,
      backgroundPosition: "center center, center center",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundSize: "contain",
      height: "fit-content",
      width: "90%",
    },
    wrapperClass: {
      marginTop: isMobile ? 110 : 60,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: isMobile ? "center" : "start",
      alignItems: "center",
    },
  };
  window.addEventListener("resize", handleResize);
  return (
    <div
      className="d-flex justify-content-center align-items-center row w-100"
      style={styles.wrapperClass}
    >
      {!isMobile && (
        <div className="d-flex justify-content-center col-md-6">
          <img
            src={process.env.PUBLIC_URL + "/Images/borrowNewImg.png"}
            alt="borrow"
          />
        </div>
      )}
      <div
        className="d-flex align-items-center col-md-6"
        style={isMobile ? styles.mobileBack : null}
      >
        <div style={subWrapperStyle}>
          <div style={headerStyle}>Borrow our stablecoin</div>
          <div style={subHeadingStyle}>
            With our platform, you can utilize your preferred assets as
            collateral to borrow the widely used decentralized stable coin,
            $PUSD
          </div>
          <div style={styles.buttonWrapper}>
            <button style={navButtonStyle}>Borrow Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Borrow;
