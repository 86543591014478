import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { useChain } from "react-moralis";
import { Container } from "@mui/system";
import CalculatorCard from "../common/CalculatorCard";
import TokenCard from "./TokenCard";
import StrategiesCard from "../common/StrategiesCard";

const useStyles = makeStyles((theme) => ({
  background: {
    // backgroundImage: 'url("images/network.png")',
    backgroundPosition: "center center,center center",
    backgroundRepeat: "no-repeat,no-repeat",
    backgroundSize: "cover,contain",
    height: "100%",
    width: "100%",
    paddingTop: "2%",
    paddingLeft: "3%",
    paddingRight: "3%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "10%",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  pageTitle: {
    textAlign: "center",
  },
  collateralTitle: {
    color: "#08FCFE",
    textAlign: "left",
    fontSize: 20,
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
}));

export default function Strategies() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        className={classes.background}
        style={{
          backgroundColor: "black",
          width: "100%",
          height: isSmall ? "fit-content" : "1000px",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          style={{ minHeight: "100%" }}
        >
          <Box>
            <Container>
              <Typography
                className={classes.pageTitle}
                style={{
                  fontSize: isSmall ? 24 : 36,
                  fontWeight: 900,
                  fontFamily: "lexend",
                  color: "white",
                  textAlign: "left",
                }}
              >
                Calculate Leverage Strategies
              </Typography>
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                spacing={3}
              >
                <Grid item xs={12} md={12}>
                  <StrategiesCard />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    className={classes.collateralTitle}
                    style={{ fontSize: 20, fontWeight: 600 }}
                  >
                    Collateral
                  </Typography>
                </Grid>

                <Grid item md={3}>
                  <Button
                    className={classes.navButton}
                    style={{
                      width: "100%",
                      color: "#000000",
                      fontWeight: 600,
                      borderRadius: 7,
                      fontSize: 12,
                      padding: "8px 8px 8px 8px",
                    }}
                  >
                    Set to Your Trove Balances
                  </Button>
                </Grid>
                <Grid item md={2} display="flex" justifyContent="flex-start">
                  <Button
                    className={classes.navButton}
                    style={{
                      width: "50%",
                      color: "#000000",
                      fontWeight: 600,
                      borderRadius: 7,
                      fontSize: 12,
                      padding: "8px 8px 8px 8px",
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid
                  item
                  md={7}
                  display="flex"
                  justifyContent="flex-start"
                ></Grid>
              </Grid>
              <Box>
                <TokenCard />
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
