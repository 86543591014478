import React, { useState } from "react";

const PeegiCards = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 576);
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 60,
    },
    card: {
      height: isMobile ? "180px" : "200px",
      width: isMobile ? "65%" : "",
      margin: isMobile ? "0 5px 15px 0" : "0 10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: isMobile ? "#171717" : "#2D1D3A",
      padding: isMobile ? 20 : 30,
      // filter: "blur(1.24562px)",
    },
    text: {
      color: "white",
      fontSize: isMobile ? 18 : 20,
      textAlign: "center",
      fontWeight: 400,
      fontFamily: "lexend",
    },
    iconPiggy: {
      height: isMobile ? 100 : 100,
      width: "auto",
    },
    iconPiggy1: {
      height: isMobile ? 58 : 70,
      width: "auto",
      marginTop: 15,
    },

    rowWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  window.addEventListener("resize", handleResize);

  const cardData = [
    {
      imgSrc: process.env.PUBLIC_URL + "/Images/newCardImg1.png",
      title: "Provide Collateral to borrow PUSD",
      imgStyle: styles.iconPiggy1,
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/Images/newCardImg2.png",
      title: "Stake your PUSD and earn PGB",
      imgStyle: styles.iconPiggy,
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/Images/chageGroup.png",
      title: "Stake your PGB and earn vePGB",
      imgStyle: styles.iconPiggy,
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/Images/newCardImg4.png",
      title: "Leverage your position",
      imgStyle: styles.iconPiggy,
    },
  ];
  return (
    <div className="container" style={styles.container}>
      <div className="row" style={styles.rowWrapper}>
        {cardData.map((card, index) => (
          <div key={index} className="col-md card" style={{ ...styles.card }}>
            <div className="card-body">
              <div
                style={{ height: 110 }}
                className="d-flex justify-content-center"
              >
                <img
                  className="card-img-top"
                  src={card.imgSrc}
                  alt="Card image cap"
                  style={card.imgStyle}
                />
              </div>
              <h5 className="card-title" style={styles.text}>
                {card.title}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PeegiCards;
