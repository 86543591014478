import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokenData: [],
  menuIndex: 0,
  walletStatus: 0,
};

const UiReducer = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setMenuIndex(state, action) {
      state.menuIndex = action.payload;
    },
    setTokenData(state, action) {
      state.tokenData = [...action.payload];
    },
    setWalletStatus(state, action) {
      state.walletStatus = action.payload;
    },
    setUsdtBalanceOfUser(state, action) {
      state.usdtBalance = action.payload;
    },
  },
});

const { actions } = UiReducer;

export const { setMenuIndex } = actions;

export default UiReducer;
