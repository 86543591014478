import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Grid, Link, Typography } from "@mui/material";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00091B",
    color: "#fff",
  },
  h1: {
    fontSize: 50,
    fontFamily: "Poppins, sans-serif",
    marginBottom: 0,
    lineHeight: 1,
    fontWeight: 700,
  },
  dot: {
    color: "#4FEBFE",
  },
  p: {
    textAlign: "center",
    margin: 18,
    fontFamily: "Muli, sans-serif",
    fontWeight: "normal",
  },
  icons: {
    textAlign: "center",
    marginTop: 60,
  },
  materialIcon: {
    marginTop: 60,
    color: "#00091B",
    background: "#fff",
    height: 15,
    width: 15,
    padding: 13,
    margin: "0 10px",
    borderRadius: "50px",
    border: "2px solid #fff",
    transition: "all 200ms ease",
    textDecoration: "none",
    position: "relative",
    "&:hover": {
      color: "#fff",
      background: "none",
      cursor: "pointer !important",
      transform: "scale(1.2)",
      WebkitTransform: "scale(1.2)",
      textDecoration: "none",
    },
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
}));

function ComingSoon() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h1" className={classes.h1}>
            Coming soon<span className={classes.dot}>...</span>
          </Typography>
        </Grid>
        <Link href="/landing" style={{ textDecoration: "none" }}>
          <Button
            className={classes.navButton}
            style={{
              width: "100%",
              color: "#000000",
              fontWeight: 600,
              borderRadius: 7,
              marginTop: 30,
            }}
          >
            Go to Home
          </Button>
        </Link>

        <Grid item className={classes.icons} style={{ marginTop: 50 }}>
          <a target="_blank" href="https://discord.com/invite/ujKEVCEvmf">
            <img
              src={
                "https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"
              }
              alt="dsicord"
              style={{
                height: 45,
                marginRight: 10,
                filter: "brightness(0) invert(1)",
              }}
            />
          </a>
          <a target="_blank" href="https://twitter.com/peegibank">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/2491px-Logo_of_Twitter.svg.png"
              alt="Twitter"
              style={{
                height: 45,
                marginleft: 10,
                filter: "brightness(0) invert(1)",
              }}
            />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default ComingSoon;
