import React, { useState } from "react";

const Cards = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 576);
  };
  const styles = {
    mainHeading: {
      width: isMobile ? "80%" : "30%",
      color: "white",
      fontFamily: "lexend",
      fontSize: isMobile ? 32 : 40,
      fontWeight: 700,
      textAlign: "center",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: isMobile ? "80%" : "20%",
    },
    card: {
      width: isMobile ? "90%" : "80%",
      height: "100%",
      paddingTop: isMobile ? 50 : 60,
      backgroundColor: "#f2f2f2",
      margin: isMobile ? "0 0 0 0" : "0 10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      borderRadius: 10,
    },
    text: {
      color: "#66DDBA",
      fontSize: 24,
      fontWeight: 500,
      fontFamily: "lexend",
      textAlign: "center",
    },
    para: {
      color: "#FFFFFF",
      fontSize: 16,
      fontWeight: 200,
      fontFamily: "lexend",
      marginTop: 20,
      textAlign: "left",
    },
    borderCard: {
      paddingLeft: "10px",
      paddingRight: "10px",
      borderTop:
        "2px solid linear-gradient(to bottom, #19D4F8 , #932BD7, #000000) 10 10%",
      borderLeft:
        "2px solid linear-gradient(to bottom, #19D4F8, #932BD7,, #000000) 10 10%",
      borderRight:
        "2px solid linear-gradient(to bottom, #19D4F8, #932BD7, #000000) 10 10%",
      borderStyle: "solid",
      borderBottom: "2px solid transparent",
      borderImage:
        "linear-gradient(to bottom, #19D4F8, #932BD7, #000000) 10 100%",
      borderImageSlice: "10 10 0 10",
      height: 170,
      webkitBorderRadius: 12,
      webkitMask:
        "linear-gradient( #19D4F8 0 0), linear-gradient( #19D4F8 0 0)",
      // webkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  };
  window.addEventListener("resize", handleResize);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="mt-4" style={styles.mainHeading}>
          Take full control of your Crypto
        </div>
      </div>
      <div classNameName="container mx-auto">
        <div
          className="d-flex row justify-content-center align-items-center"
          style={{}}
        >
          <div className="col-md card" style={styles.card}>
            <div style={styles.borderCard}>
              <div className="card-body">
                <h5 className="card-title" style={styles.text}>
                  0% Interest Rate
                </h5>
                <p className="card-text" style={styles.para}>
                  Instead of fluctuating interest rates, Peegibank charges a
                  small, one time fee to lend PUSD.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md card" style={styles.card}>
            <div style={styles.borderCard}>
              <div className="card-body">
                <h5 className="card-title" style={styles.text}>
                  Convenient
                </h5>
                <p className="card-text" style={styles.para}>
                  Instead of creating separate debt positions, users can borrow
                  against their entire portfolio, providing greater protection
                  against liquidation.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md card" style={styles.card}>
            <div style={styles.borderCard}>
              <div className="card-body">
                <h5 className="card-title" style={styles.text}>
                  Capital Efficient
                </h5>
                <p className="card-text" style={styles.para}>
                  Peegibank allows users to obtain leverage upto 11X on their
                  base assets, stake assets, LP Tokens and upto 20X on interest
                  bearing stablecoins, with low minimum collateral ratios.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
