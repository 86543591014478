import { Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  footerStyle: {
    fontSize: 15,
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#757C88",
  },
  iconStyles: {
    height: 25,
    width: "auto",
    marginRight: 20,
    cursor: "pointer",
  },
}));
const iconStyles = {
  height: 25,
  width: "auto",
  marginRight: 20,
  // marginTop: 0,
};

function FooterCard() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box style={{ marginBottom: "30px" }}>
        <Divider sx={{ borderBottomWidth: 2 }} color="#F0F0F0" />
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Typography
              style={{ fontSize: isSmall ? 12 : 18 }}
              className={classes.footerStyle}
              mx={1}
            >
              Audit
            </Typography>
            <Typography
              style={{ fontSize: isSmall ? 12 : 18 }}
              className={classes.footerStyle}
              mx={1}
            >
              Blog
            </Typography>
            <Typography
              style={{ fontSize: isSmall ? 12 : 18 }}
              className={classes.footerStyle}
              mx={1}
            >
              Docs
            </Typography>
            <Typography
              style={{ fontSize: isSmall ? 12 : 18 }}
              className={classes.footerStyle}
              mx={1}
            >
              Terms
            </Typography>
          </Box>
          <Box>
            <img
              src={process.env.PUBLIC_URL + "/Images/email.png"}
              alt="Email"
              style={{
                height: isSmall ? 14 : 25,
                width: "auto",
                marginRight: isSmall ? 10 : 20,
                cursor: "pointer",
              }}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/facebook.png"}
              alt="Facebook"
              style={{
                height: isSmall ? 16 : 25,
                width: "auto",
                marginRight: isSmall ? 10 : 20,
                cursor: "pointer",
              }}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/instagram.png"}
              alt="Instagram"
              style={{
                height: isSmall ? 16 : 25,
                width: "auto",
                marginRight: isSmall ? 10 : 20,
                cursor: "pointer",
              }}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/twitter.png"}
              alt="Twitter"
              style={{
                height: isSmall ? 16 : 25,
                width: "auto",
                marginRight: isSmall ? 10 : 20,
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FooterCard;
