import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    height: "auto",
    width: "100%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    color: "white",
    textAlign: "left",
    marginTop: 10,
  },
  description: {
    fontWeight: 600,
    color: "white",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#757C88",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
  //   buttonMax: {
  //     fontSize: 72,
  //     background: "-webkit-linear-gradient(#eee, #333)",
  //     webkitBackgroundClip: "text",
  //     webkitTextFillColor: "transparent",
  //   },
  buttonFirst: {
    width: "100%",
    color: "#212121",
    backgroundColor: "#eeeeee",
    padding: "7px 20px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  buttonSecond: {
    width: "100%",
    color: "white",
    backgroundColor: "#6A55EA",
    padding: "7px 20px",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "8px 30px 8px 30px",
    },
  },
  buttonWrapper: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "auto",
    width: "100%",
    border: "1px solid #545464",
    borderRadius: 8,
  },
  inputWrapper: {
    border: "1px solid #757C88",
    padding: "8px 10px 8px 10px",
    borderRadius: 5,
    backgroundColor: "rgba(106, 85, 234,0.03)",
    marginTop: 10,
  },
}));

export default function StakeCard() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [amount, setAmount] = useState(0);

  const [orderType, setOrderType] = useState("stake");
  const navigate = useNavigate();
  return (
    <Box
      pt={0}
      className={classes.card}
      style={{
        paddingLeft: isSmall ? 15 : 20,
        marginTop: isSmall ? 15 : 20,
        marginBottom: isSmall ? 15 : 20,
        paddingTop: isSmall ? 15 : 20,
        paddingBottom: isSmall ? 15 : 20,
        paddingLeft: isSmall ? 15 : 20,
        paddingRight: isSmall ? 15 : 20,
      }}
    >
      <Typography
        className={classes.title}
        style={{
          fontSize: 18,
          fontWeight: 600,
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        Stake Peegi
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginLeft: isSmall ? 0 : 3 }}
        >
          <Grid container display="flex" justifyContent="space-between">
            <Grid item md={6}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  className={classes.title}
                  style={{
                    fontSize: isSmall ? 16 : 18,
                    fontWeight: isSmall ? 600 : 500,
                    color: "#757C88",
                  }}
                >
                  Staked
                </Typography>
                <img
                  src="Images/infoQuestion.png"
                  style={{ height: isSmall ? 16 : 20, marginRight: 10 }}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Typography
                className={classes.title}
                style={{
                  fontSize: isSmall ? 16 : 18,
                  fontWeight: 500,
                  textAlign: isSmall ? "left" : "",
                }}
              >
                Rewards
              </Typography>
            </Grid>
          </Grid>

          <Grid container display="flex" justifyContent="space-between">
            <Grid item md={6}>
              <Typography
                className={classes.description}
                style={{
                  fontSize: isSmall ? 16 : 24,
                  fontWeight: 600,
                }}
              >
                10.00
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                className={classes.description}
                style={{ fontSize: isSmall ? 16 : 24, fontWeight: 600 }}
              >
                0.35
              </Typography>
            </Grid>
          </Grid>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item md={6}>
              <Typography
                className={classes.value}
                style={{ fontSize: 18, fontWeight: 500 }}
              >
                Value: $35.00
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                className={classes.value}
                style={{ fontSize: 18, fontWeight: 500 }}
              >
                Value: $0.67
              </Typography>
              <Box display="flex" justifyContent="flex-start" my={2}>
                <button className={classes.navButton}>Stake Peegi</button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Divider sx={{ borderBottomWidth: 2 }} color="white" />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          mt={2}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid container display="flex" justifyContent="space-between">
            <Grid item md={6}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  className={classes.title}
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    marginLeft: isSmall ? 0 : 25,
                  }}
                >
                  Rewards
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Typography
                className={classes.title}
                style={{ fontSize: 18, fontWeight: 500 }}
              >
                3%
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ width: "100%" }}>
            <Box className={classes.buttonWrapper}>
              <Box
                className={classes.buttonFirst}
                style={{
                  background:
                    orderType === "stake"
                      ? "linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)"
                      : "light"
                      ? "transparent"
                      : "#646892",
                  color: orderType === "stake" ? "#000000" : "#757C88",
                  transition: "background-color 0.2s ease",
                  fontSize: 20,
                  fontWeight: 600,
                  borderRadius: orderType === "stake" ? "7px" : "10px",
                }}
                onClick={() => {
                  setOrderType("stake");
                  navigate("?type=stake");
                }}
              >
                Stake
              </Box>
              <Box
                className={classes.buttonSecond}
                style={{
                  background:
                    orderType === "unstake"
                      ? "linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)"
                      : "red"
                      ? "transparent"
                      : "orange",
                  color: orderType === "unstake" ? "#000000" : "#757C88",
                  transition: "background-color 0.2s ease",
                  fontSize: 20,
                  fontWeight: 600,
                  borderRadius: orderType === "unstake" ? "7px" : "10px",
                }}
                onClick={() => {
                  setOrderType("unstake");
                  navigate("?type=unstake");
                }}
              >
                Unstake
              </Box>
            </Box>
          </Grid>
          <Grid item md={9} my={1}>
            <Typography
              className={classes.title}
              style={{ fontSize: 18, fontWeight: 500 }}
            >
              Stake Peegi to earn rewards
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={8}>
            <Box
              className={classes.inputWrapper}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: isSmall ? "100%" : "100%" }}
            >
              <Input
                value={amount}
                onInput={(event) => setAmount(event.target.value)}
                fullWidth
                placeholder="0"
                disableUnderline
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: "#f9f9f9",
                  width: isSmall ? "60%" : "70%",
                }}
              />
              <Typography
                variant="body2"
                className={classes.para}
                textAlign="center"
                fontWeight={700}
                ml={1}
                style={{ color: "white", fontSize: 20, fontWeight: 600 }}
              >
                = $1000
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              mt={1}
              style={{
                border: "1px solid #6B6E7D",
                padding: "3px 3px 3px 3px",
                borderRadius: 7,
                backgroundColor: "rgba(106, 85, 234,0.03)",
              }}
            >
              <Button className="maxButton" style={{ fontSize: 20 }}>
                Max
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={9} my={2}>
          <Typography
            className={classes.title}
            style={{ fontSize: 18, fontWeight: 500 }}
          >
            Balance: 22.3454idxCVX
          </Typography>
        </Grid>
        <Grid item md={12} my={2}>
          <Button
            className={classes.navButton}
            style={{
              width: "98%",
              color: "#000000",
              fontWeight: 600,
              borderRadius: 7,
            }}
          >
            {orderType === "stake" ? "Stake" : "Unstake"}
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
