import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Input,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    height: "auto",
    width: "100%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
  inputWrapper: {
    border: "1px solid #757C88",
    padding: "3px 10px 3px 10px",
    borderRadius: 5,
    backgroundColor: "rgba(106, 85, 234,0.03)",
    marginTop: 10,
    width: "60%",
  },
}));

export const PoolStakeDialog = ({
  openDialog,
  handleClickCloseStakeDialog,
  orderType,
}) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(0);
  const [isStake, setIsStake] = useState(true);

  const handleClick = () => {
    if (openDialog) {
      setIsStake(false);
    } else {
      setIsStake(false);
    }
    handleClickCloseStakeDialog();
  };
  return (
    <>
      <Backdrop className={classes.backdrop} />
      <Dialog
        open={openDialog}
        onClose={handleClickCloseStakeDialog}
        PaperProps={{
          style: {
            background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
            borderRadius: 14,
          },
        }}
      >
        <Box className="d-flex justify-content-between p-3">
          <Typography
            style={{ color: "white", fontSize: 18, fontWeight: 600 }}
            onClick={handleClick}
          >
            {orderType === "stake" ? "Stake" : "Unstake"}
          </Typography>
          <Close
            style={{ color: "white", cursor: "pointer" }}
            onClick={handleClickCloseStakeDialog}
          />
        </Box>
        <Divider sx={{ borderBottomWidth: 2 }} color="white" />
        <DialogContent style={{ width: 370 }}>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              style={{ color: "white", fontSize: 18, fontWeight: 500 }}
            >
              Deposit
            </Typography>
            <Typography
              style={{ color: "#757C88", fontSize: 18, fontWeight: 500 }}
            >
              Max: 0.0000
            </Typography>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box className={classes.inputWrapper}>
                <Input
                  value={amount}
                  onInput={(event) => setAmount(event.target.value)}
                  fullWidth
                  placeholder="0"
                  disableUnderline
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#f9f9f9",
                  }}
                />
              </Box>

              <Box>
                <Typography
                  style={{ color: "white", fontSize: 18, fontWeight: 500 }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/Images/pusdIcon.png"}
                    style={{ width: 35, height: "auto" }}
                  />{" "}
                  PUSD
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            className={classes.navButton}
            style={{
              width: "60%",
              color: "#000000",
              fontWeight: 600,
              borderRadius: 20,
              marginTop: 50,
            }}
          >
            Confirm
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default PoolStakeDialog;
