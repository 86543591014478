export const tokenList = [
  {
    name: "MATIC",
    symbol: "MATIC",
    id: "matic-network",
    address: "0x0000000000000000000000000000000000001010",
    decimals: 18,
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
  },
  {
    name: "Sleep Token",
    symbol: "PeegiBank",
    id: "polkabridge",
    address: "0xb94d207a3fBdb312cef2e5dBEb7C22A76516BE37",
    decimals: 18,
    logoURI:
      "https://static.vecteezy.com/system/resources/previews/012/623/102/original/pink-piggy-bank-dropping-coins-icon-on-transparent-background-3d-rendering-png.png",
  },
  {
    name: "Sleep Token",
    symbol: "SLEEPT",
    id: "polkabridge",
    address: "0xb94d207a3fBdb312cef2e5dBEb7C22A76516BE37",
    decimals: 18,
    logoURI:
      "https://cdn3d.iconscout.com/3d/free/thumb/squigly-globe-3494833-2926648@0.png",
  },
  {
    name: "Polkabridge",
    symbol: "PBR",
    id: "polkabridge",
    address: "0x0d6ae2a429df13e44a07cd2969e085e4833f64a0",
    decimals: 18,
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/8320.png",
  },
];
