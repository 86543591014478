import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { purple } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    padding: "10px 10px 10px 10px",
    width: "98%",
    // border: "1px solid #414141",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 12,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  heading: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: "0.02em",
    color: "#f9f9f9",
    fontFamily: "Inter",
    textAlign: "center",
  },
  headingMain: {
    fontWeight: 500,
    fontSize: "16px important",
    letterSpacing: "0.02em",
    color: "#f9f9f9",
    fontFamily: "Inter",
    textAlign: "center",
  },
  para: {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: "0.02em",
    fontFamily: "Inter",
    color: "#757C88",
    textAlign: "center",
  },
  imageTable: {
    height: 30,
  },
}));

export default function TokenCard() {
  const classes = useStyles();
  const theme = useTheme();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  // Sample data
  const tokenData = [
    {
      assetImage:
        "https://cdn3d.iconscout.com/3d/premium/thumb/bitcoin-cryptocurrency-5245184-4386258.png?f=webp",
      asset: "ETH",
      name: "Ethereum",
      subName: "Eth",
      amount: "3,327",
      price: "$1,323",
      buyPrice: "$1,200",
      pnl: "+$123",
      holding: "20%",
    },
    {
      assetImage:
        "https://cdn3d.iconscout.com/3d/premium/thumb/chainlink-coin-4199896-3478982@0.png",
      asset: "BTC",
      name: "Bitcoin",
      amount: "2,512",
      price: "$56,290",
      buyPrice: "$50,000",
      pnl: "+$12,000",
      holding: "15%",
    },
    {
      assetImage:
        "https://cdn3d.iconscout.com/3d/premium/thumb/ftx-token-coin-ftt-6445026-5326786.png",
      asset: "BTC",
      name: "Bitcoin",
      amount: "2,512",
      price: "$56,290",
      buyPrice: "$50,000",
      pnl: "+$12,000",
      holding: "15%",
    },
    {
      assetImage:
        "https://cdn3d.iconscout.com/3d/premium/thumb/ethereum-coin-classic-6445021-5326781.png",
      asset: "BTC",
      name: "Bitcoin",
      amount: "2,512",
      price: "$56,290",
      buyPrice: "$50,000",
      pnl: "+$12,000",
      holding: "15%",
    },
    // Add more data objects as needed
  ];

  return (
    <Box pt={0} px={3} className={classes.card}>
      <Box pt={2} display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography
            variant="body2"
            className={classes.headingMain}
            textAlign="left"
            fontWeight={600}
            fontSize={16}
          >
            Assets to add
          </Typography>
          <Typography
            mt={1}
            mb={2}
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={500}
            fontSize={14}
          >
            Select assets to add as collateral
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Checkbox
            {...label}
            defaultChecked
            sx={{
              color: "#6750A4",
              "&.Mui-checked": {
                color: "#6750A4",
              },
            }}
          />
          <Typography
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={500}
            fontSize={14}
          >
            Show assets with 0 balance
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        style={{ borderBottom: "0.5px solid #212121" }}
        pb={1}
        pt={2}
      >
        <Grid item md={4}>
          <Typography
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={400}
          >
            Asset
          </Typography>
        </Grid>

        <Grid item md={2}>
          <Typography
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={400}
          >
            Safety Ratio
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={400}
          >
            Total Deposited
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={400}
          >
            Wallet
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={400}
          >
            Rewards
          </Typography>
        </Grid>
        {/* <Grid item md={2}>
          <Typography
            variant="body2"
            className={classes.para}
            textAlign="left"
            fontWeight={400}
          >
            Holding
          </Typography>
        </Grid> */}
      </Grid>

      {tokenData.map((token, index) => (
        <Link
          href={`/asset-detail/${token.name}`}
          style={{ textDecoration: "none" }}
        >
          <Grid
            container
            key={index}
            style={{ borderBottom: "0.5px solid #212121" }}
            pb={1}
            pt={1}
          >
            <Grid item md={4} display="flex" justifyContent="flex-start">
              <img
                src={token.assetImage}
                alt={token.name}
                className={classes.imageTable}
              />
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
              >
                <Typography
                  variant="body2"
                  className={classes.heading}
                  textAlign="left"
                  fontWeight={600}
                >
                  {token.name}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.para}
                  textAlign="left"
                  fontWeight={600}
                >
                  {token.asset}
                </Typography>
              </Box>
            </Grid>

            <Grid item md={2}>
              <Typography
                variant="body2"
                className={classes.heading}
                textAlign="left"
                fontWeight={600}
              >
                {token.amount}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                variant="body2"
                className={classes.heading}
                textAlign="left"
                fontWeight={600}
              >
                {token.price}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                variant="body2"
                className={classes.heading}
                textAlign="left"
                fontWeight={600}
              >
                {token.buyPrice}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                variant="body2"
                className={classes.heading}
                textAlign="left"
                fontWeight={600}
              >
                {token.pnl}
              </Typography>
            </Grid>
          </Grid>
        </Link>
      ))}
    </Box>
  );
}
