import {
  Box,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AssetDetailCard from "./AssetDetailCard";
import ColleteralStatsCard from "./ColleteralStatsCard";
import BalanceChart from "./chart/BalanceChart";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  toggleGroupSelected: {
    backgroundColor: "#434658",
  },
}));
function AssetDetailComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState("tvl");
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate("/");
    }
  };

  const handleToggle = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Container>
        <Box display="flex" justifyContent="flex-start">
          <img
            src={process.env.PUBLIC_URL + "/Images/backArrow.png"}
            height="25px"
            onClick={goBack}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          className="mt-4"
        >
          <img
            src={process.env.PUBLIC_URL + "/Images/avaxIcon.png"}
            height="30px"
          />
          <Typography
            style={{
              fontSize: 32,
              fontWeight: 800,
              color: "white",
              marginLeft: 10,
              marginRight: 5,
            }}
          >
            Wrapped AVAX
          </Typography>
          <img
            src={process.env.PUBLIC_URL + "/Images/viewMore.png"}
            height="20px"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="mt-4"
        >
          <div>
            <ToggleButtonGroup
              value={value}
              style={{ backgroundColor: "#272937", borderRadius: 12 }}
              exclusive
              onChange={handleToggle}
              className={{
                grouped: value === "tvl" ? null : classes.toggleGroupSelected,
              }}
            >
              <ToggleButton value="tvl">
                <Typography
                  variant="body1"
                  style={{ color: value === "tvl" ? "white" : "#8A93A6" }}
                >
                  TVL
                </Typography>
              </ToggleButton>

              <ToggleButton value="price">
                <Typography
                  variant="body1"
                  style={{ color: value === "tvl" ? "#8A93A6" : "white" }}
                >
                  Price
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            {/* {value === "tvl" ? (
              <Typography variant="h6">Display TVL data here</Typography>
            ) : (
              <Typography variant="h6">Display Price data here</Typography>
            )} */}
          </div>
          <div>
            <ToggleButtonGroup
              value={value}
              style={{ backgroundColor: "#272937", borderRadius: 12 }}
              exclusive
              onChange={handleToggle}
              className={{
                grouped: value === "tvl" ? null : classes.toggleGroupSelected,
              }}
            >
              <ToggleButton value="tvl">
                <Typography
                  variant="body1"
                  style={{ color: value === "tvl" ? "white" : "#8A93A6" }}
                >
                  1D
                </Typography>
              </ToggleButton>

              <ToggleButton value="price">
                <Typography
                  variant="body1"
                  style={{ color: value === "tvl" ? "#8A93A6" : "white" }}
                >
                  1W
                </Typography>
              </ToggleButton>
              <ToggleButton value="price">
                <Typography
                  variant="body1"
                  style={{ color: value === "tvl" ? "#8A93A6" : "white" }}
                >
                  1M
                </Typography>
              </ToggleButton>
              <ToggleButton value="price">
                <Typography
                  variant="body1"
                  style={{ color: value === "tvl" ? "#8A93A6" : "white" }}
                >
                  1Y
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Box>
        <Box className="mt-4">
          <Typography
            style={{
              fontSize: 30,
              fontWeight: 700,
              color: "white",
              textAlign: "left",
            }}
          >
            $344,160.28
          </Typography>
        </Box>
        <Box>
          <BalanceChart />
        </Box>
        <Grid style={{ display: isSmall ? "" : "flex", width: "100%" }}>
          <Grid item md={7} xs={10}>
            <AssetDetailCard />
          </Grid>

          <Grid item md={5} xs={10} className="mr-2">
            <ColleteralStatsCard />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AssetDetailComponent;
