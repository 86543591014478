import React, { useState } from "react";

const TopHeader = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 576);
  };
  const styles = {
    header: {
      fontFamily: "Lexend",
      fontSize: isMobile ? 10 : 20,
      fontWeight: isMobile ? 600 : 400,
      color: "#41ABEC",
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
    },
    mainHeading: {
      fontFamily: "Lexend",
      fontSize: isMobile ? 30 : 50,
      fontWeight: 700,
      color: "#FFFFFF",
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
      textAlign: isMobile ? "center" : "left",
    },

    headerWrapper: {
      height: isMobile ? "50vh" : "78vh",
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
      alignItems: "center",
      marginLeft: isMobile ? 0 : "5%",
      marginTop: isMobile ? 15 : "",
    },
    para: {
      fontFamily: "Lexend",
      fontSize: isMobile ? 10 : 18,
      fontWeight: 600,
      color: "#FFFFFF",
      textAlign: isMobile ? "center" : "left",
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
    },
    navButton: {
      background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
      border: "none",
      borderRadius: "100px",
      boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
      boxSizing: "border-box",
      color: "black",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "600",
      fontFamily: "lexend",
      outline: "none",
      padding: "7px 30px",
      transform: "translateY(0)",
      transition: "transform 150ms, box-shadow 150ms",
      userSelect: "none",
      WebkitUserSelect: "none",
      touchAction: "manipulation",
      textAlign: isMobile ? "center" : "left",
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
      alignItems: "center",
    },
    mainImageTop: {
      maxHeight: isMobile ? "200px" : "450px",
      width: "auto",
      marginRight: isMobile ? "0%" : "7%",
    },
    imageContainer: {
      height: isMobile ? "200px" : "450px",
      overflow: "hidden",
      // display: isMobile ? "none" : "",
    },

    colWrapper: {
      width: isMobile ? "100%" : "null",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
      alignItems: "center",
    },
    paraWrapper: {
      width: isMobile ? "85%" : "97%",
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-start",
      alignItems: "center",
    },
    imageWrapper: {
      display: "flex",
      justifyContent: isMobile ? "center" : "flex-end",
      alignItems: "center",
    },
  };

  window.addEventListener("resize", handleResize);
  return (
    <>
      <div style={styles.headerWrapper}>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-md-5 col-xs-12" style={styles.colWrapper}>
            <div>
              <div style={styles.header}>
                BUILT ON ARBITRUM, POWERED BY YOU.
              </div>
              <div className="d-flex start" style={styles.mainHeading}>
                Revolutionize Your
                <br />
                Investments
              </div>
              <div className="mx-auto" style={styles.paraWrapper}>
                <div style={styles.paraWrapper}>
                  <p style={styles.para}>
                    Unlock the power of your assets with PeegiBank’s Cross
                    Margin Lending protocol on the Arbitrum Network
                  </p>
                </div>
              </div>
              <div className="d-flex start" style={styles.buttonWrapper}>
                <a href="#borrow-now" style={{ textDecoration: "none" }}>
                  <button className="nav-button" style={styles.navButton}>
                    Borrow Now
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-xs-12" style={styles.imageContainer}>
            <div style={styles.imageWrapper}>
              <img
                src={process.env.PUBLIC_URL + "/Images/landingHero.png"}
                style={styles.mainImageTop}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
