import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
// import { useChain } from "react-moralis";
import { Container } from "@mui/system";
import PoolCard from "./PoolCard";
import FarmCard from "../common/FarmCard";
import Footer from "../../components/Footer";
import FooterCard from "../common/FooterCard";
import StakeCard from "../common/StakeCard";
import HistoryCard from "../common/HistoryCard";
import RedeemCard from "../common/RedeemCard";

const useStyles = makeStyles((theme) => ({
  background: {
    // backgroundImage: 'url("images/network.png")',
    backgroundPosition: "center center,center center",
    backgroundRepeat: "no-repeat,no-repeat",
    backgroundSize: "cover,contain",
    height: "100%",
    width: "100%",
    paddingTop: "2%",
    paddingLeft: "3%",
    paddingRight: "3%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "10%",
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  pageTitle: {
    textAlign: "left",
  },
}));

export default function Redeem() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        className={classes.background}
        style={{
          backgroundColor: "black",
          width: "100%",
          height: isSmall ? 730 : "800px",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          style={{ minHeight: "100%" }}
        >
          <Box>
            <Container>
              <Typography
                className={classes.pageTitle}
                style={{
                  fontSize: 36,
                  fontWeight: 900,
                  fontFamily: "lexend",
                  color: "white",
                  textAlign: isSmall ? "center" : "",
                }}
              >
                Redeem PUSD
              </Typography>
              <Grid
                container
                display={"flex"}
                justifyContent="center"
                spacing={6}
              >
                <Grid item md={12} style={{ display: isSmall ? "none" : "" }}>
                  <RedeemCard />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
