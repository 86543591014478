import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Input,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },

  navButton: {
    backgroundColor: "transparent",
    border: "1px solid red",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },

  compoundButton: {
    border: "1px solid #08FCFE",
    borderRadius: "8px",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontFamily: "lexend",
    padding: "3px 10px",
    textAlign: "center",
  },
}));

export const StabilityDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [amount, setAmount] = useState(0);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
            borderRadius: 14,
          },
        }}
      >
        <Box className="d-flex justify-content-between p-3">
          <Typography style={{ color: "white", fontSize: 18, fontWeight: 600 }}>
            Stability Pool Rewards
            <Tooltip
              title="PGB Tokens rewards automatically accure from
              depositing in the Stability Pool."
              placement="right"
            >
              <img
                src="Images/infoQuestion.png"
                style={{ height: 20, marginLeft: 10 }}
              />
            </Tooltip>
          </Typography>
          <Close
            style={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        <Divider sx={{ borderBottomWidth: 2 }} color="white" />
        <DialogContent style={{ width: 520, height: 280 }}>
          <DialogContentText>
            <Typography
              color="#757C88"
              fontSize={sm ? 12 : 14}
              fontWeight={500}
              //   style={{ color: "#757C88", fontSize: 14, fontWeight: 500 }}
            >
              Claimable Collaterals:
              <Tooltip
                title="Liquidation rewards are shown below. You may
                either select as subset of assets to auto-compound
                to PUSD back into your Stability Pool Position or 
                claim all rewards and sell them yourself."
                placement="right"
              >
                <img
                  src="Images/infoQuestion.png"
                  style={{ height: 20, marginLeft: 10 }}
                />
              </Tooltip>
            </Typography>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Grid item md={12} display="flex">
              <Grid item md={6}>
                <Typography
                  style={{ color: "white", fontSize: 14, fontWeight: 500 }}
                >
                  TO COMPOUND
                  <Tooltip
                    title="You need to have at least 1 YUSD worth of this
                    Collaterals to auto compound."
                    placement="right"
                  >
                    <img
                      src="Images/infoQuestion.png"
                      style={{ height: 20, marginLeft: 10 }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item md={6} display="flex" justifyContent="space-around">
                <Typography
                  style={{ color: "white", fontSize: 14, fontWeight: 500 }}
                >
                  Token
                </Typography>
                <Typography
                  style={{ color: "white", fontSize: 14, fontWeight: 500 }}
                >
                  Amount
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item md={12} display="flex" justifyContent="space-between">
              <Grid item md={6}>
                <Button
                  className={classes.compoundButton}
                  style={{
                    border: "1px solid #08FCFE",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "white",
                    borderRadius: 8,
                  }}
                >
                  Auto Compound
                </Button>
              </Grid>
              <Grid item md={6} display="flex" justifyContent="space-around">
                <Button
                  className={classes.compoundButton}
                  style={{
                    border: "1px solid #08FCFE",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "white",
                    borderRadius: 8,
                  }}
                >
                  0.5%
                </Button>
                <Button
                  className={classes.compoundButton}
                  style={{
                    border: "1px solid #08FCFE",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "white",
                    borderRadius: 8,
                  }}
                >
                  1%
                </Button>
                <Button
                  className={classes.compoundButton}
                  style={{
                    border: "1px solid #08FCFE",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "white",
                    borderRadius: 8,
                  }}
                >
                  X%
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item md={12} display="flex" justifyContent="space-between">
              <Grid item md={9}>
                <Typography
                  style={{ color: "white", fontSize: 16, fontWeight: 500 }}
                >
                  Min PUSD Expect to Receive: 0.0000
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              md={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item md={6}>
                <Button
                  style={{
                    border: "1px solid #08FCFE",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "white",
                    borderRadius: 8,
                  }}
                >
                  Claim Rewards
                </Button>
              </Grid>
              <Grid item md={6}>
                <Button
                  style={{
                    border: "1px solid #08FCFE",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "white",
                    borderRadius: 8,
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StabilityDialog;
