import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  useMediaQuery,
  ListItem,
  SwipeableDrawer,
  CircularProgress,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
// import Link from "next/link";
// import { useRouter } from "next/router";
// import {
//   toggleHeaderMenuExpanded,
//   updateMaticBalance,
// } from "../../reducers/UiReducer";
// import ethersServiceProvider from "../../services/ethersServiceProvider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { useWeb3Auth } from "../../hooks/useWeb3Auth";
import { Redeem, Wallet } from "@mui/icons-material";
import { Container } from "@mui/system";
import Profile from "../common/dialogBox/Profile";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { Web3Button, useWeb3Modal } from "@web3modal/react";
import { useConnect, useDisconnect } from "wagmi";
import { formattedAddress, isMetaMaskInstalled } from "../../utils/helper";
import WalletDialog from "./WalletDialog";

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    paddingLeft: "3%",
    paddingRight: "3%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  linkItems: {
    fontWeight: 400,
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  paper: {
    top: 0,
    left: "unset !important",
    right: 0,
    width: 220,
    borderRadius: "0",
    backgroundColor: "black",
    transformOrigin: "16px -1px !important",
    paddingTop: 20,
    overflow: "hidden",
  },
  listItem: {
    justifyContent: "flex-start",
  },
  // buttonConnect: {
  //   backgroundColor: theme.palette.secondary.main,
  //   color: "white",
  //   "&:hover": {
  //     backgroundColor: theme.palette.secondary.main,
  //     color: "white",
  //   },
  // },
  buttonConnect: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    // border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "white",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 30px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const web3AuthSC = true;
  const [walletAddress, setWalletAddress] = useState(null);
  const matches = useMediaQuery("(min-width:1153px)");
  const [showPopup, setShowPopup] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const { account, chainId, isActive } = useActiveWeb3React();

  const handleOpenProfile = () => {
    setProfileOpen(true);
  };

  const handleCloseProfile = () => {
    setProfileOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const connectWallet = async () => {
    // Connect to Metamask
    const provider = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    // Get the connected wallet address
    const [address] = provider;

    // Update the state variable with the address
    setWalletAddress(address);
  };
  useEffect(() => {
    const checkWallet = async () => {
      const provider = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (provider.length === 0) {
        setShowPopup(true);
      }
    };

    checkWallet();
  }, []);

  return (
    <Box className={classes.background}>
      <Container>
        <header>
          <Grid
            style={{
              color: theme.palette.primary.contrastText,
              display: "flex",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Grid display="flex" alignItems="center" justifyContent="flex-end">
              <Box>
                <Button
                  style={{
                    textDecoration: "none",
                    textTransform: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="small" fontWeight={600}>
                    <Link
                      style={{
                        textDecoration: "none",
                        textTransform: "none",
                        color: "white",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                      target="_blank"
                      onClick={handleOpenProfile}
                    >
                      Portfolio
                    </Link>{" "}
                  </Typography>
                </Button>
              </Box>
              <Link style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                    borderRadius: "20px",
                    marginRight: 1,
                    fontWeight: 500,
                    color: "white",
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    History
                  </span>
                </Box>
              </Link>
              {isActive && <Web3Button />}
              {!isActive && (
                <Button
                  color="primary"
                  minWidth={sm ? 80 : 140}
                  fontSize={sm ? 10 : 16}
                  style={{
                    color: "black",
                    borderRadius: 100,
                    padding: "6px 14px",
                  }}
                  disabled={isActive}
                  className={classes.buttonConnect}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {isActive ? formattedAddress(account) : "Connect Wallet"}
                </Button>
              )}
            </Grid>
          </Grid>
          <Box>
            <Profile open={isProfileOpen} handleClose={handleCloseProfile} />
            <WalletDialog open={open} handleClose={handleClose} />
          </Box>
        </header>
      </Container>
    </Box>
  );
};

export default Header;
