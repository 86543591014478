import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import PoolCard from "./PoolCard";
import TokenCard from "./TokenCard";
import TokenDesposit from "../common/TokenDesposit";
// import PoolCard from "./PoolCard";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: 'url("images/network.png")',
    backgroundPosition: "center center,center center",
    backgroundRepeat: "no-repeat,no-repeat",
    backgroundSize: "cover,contain",
    height: "680px",
    width: "100%",
    paddingTop: "2%",
    paddingLeft: "3%",
    paddingRight: "3%",
    backgroundColor: "black",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0%",
      paddingLeft: 5,
      paddingRight: 5,
      height: "fit-content",
    },
  },
  pageTitle: {
    fontWeight: 600,
    color: "white",
    textAlign: "left",
    fontSize: "24px !important",
    fontWeight: "600 !important",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      spacing: 8,
    },
  },
  topCard: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      gap: 0,
    },
  },

  pageSubtitle: {
    color: "#bdbdbd",
    textAlign: "left",
  },
}));

export default function Borrows() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  let poolsData = [
    {
      title: "Net Worth",
      description: "1000",
      // type: "ACCUMULATION",
    },
    {
      title: "Total Rewards",
      description: "10000",
      // type: "GRID",
    },
    {
      title: "Total Assets",
      description: "10000",
      // type: "GRID",
    },
    {
      title: "Colleteral Balance",
      description: "10000",
      // type: "GRID",
    },
  ];

  return (
    <Box>
      <Box className={classes.background}>
        <Container>
          <Typography
            className={classes.pageTitle}
            style={{ marginBottom: isSmall ? 20 : 0 }}
          >
            Dashboard
          </Typography>
          <Grid container spacing={isSmall ? 9 : 4} className={classes.topCard}>
            {poolsData.map((singlePool, index) => (
              <Grid item md={isSmall ? 9 : 3} key={index}>
                <PoolCard poolStaticData={singlePool} index={index} />
              </Grid>
            ))}
          </Grid>
        </Container>
        <Box>
          {isSmall ? (
            <>
              <TokenDesposit />
              <TokenDesposit />
              <TokenDesposit />
              <TokenDesposit />
            </>
          ) : (
            <TokenCard />
          )}
        </Box>
      </Box>
    </Box>
  );
}
