import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "98%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    fontWeight: 500,
    color: "#757C88",
    // textAlign: "left",
    fontSize: 12,
  },
  description: {
    fontWeight: 400,
    color: "#08FCFE",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#f9f9f9",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
  buttonLast: {
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "24px",
    borderStyle: "none",
    boxShadow:
      "rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0",
    boxSizing: "border-box",
    color: "#3c4043",
    cursor: "pointer",
    display: "inline-flex",
    fill: "currentcolor",
    fontFamily: '"Google Sans",Roboto,Arial,sans-serif',
    fontSize: "14px",
    fontWeight: 500,
    height: "48px",
    justifyContent: "center",
    letterSpacing: ".25px",
    lineHeight: "normal",
    maxWidth: "100%",
    overflow: "visible",
    padding: "2px 24px",
    position: "relative",
    textAlign: "center",
    textTransform: "none",
    transition:
      "box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
    width: "auto",
    willChange: "transform,opacity",
    zIndex: 0,
    "&:hover": {
      background: "#F6F9FE",
      color: "#174ea6",
    },
    "&:active": {
      boxShadow:
        "0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%)",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid #4285f4",
    },
    "&:not(:disabled)": {
      boxShadow:
        "rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px",
    },
    "&:not(:disabled):hover": {
      boxShadow:
        "rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px",
    },
    "&:not(:disabled):focus": {
      boxShadow:
        "rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px",
    },
  },
}));

export default function ColleteralStatsCard() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Box pt={0} className={classes.card}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 24,
              fontWeight: 600,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Collateral Stats
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Trove deposit
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Trove deposit
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Wallet Balance
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Intrest Rate
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            TVL
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Rewards
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Max LTV
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Safety Ratio
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={classes.title}
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#757C88",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Oracle Price
          </Typography>
          <Typography
            className={classes.title}
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: "white",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            0.0000
          </Typography>
        </Box>
      </Box>
    </>
  );
}
