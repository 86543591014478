import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import RedeemDialog from "./dialogBox/RedeemDialog";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "100%",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    color: "white",
    textAlign: "left",
    marginTop: 10,
  },
  description: {
    fontWeight: 600,
    color: "#757C88",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#757C88",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
}));

export default function RedeemCard() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(isSmall);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSmall) {
      setOpen(true);
    }
  }, [isSmall]);

  const handleRedeemNow = useCallback(() => {
    if (!isSmall) {
      handleClickOpen();
    }
  }, [isSmall]);

  return (
    <Box
      pt={0}
      className={classes.card}
      style={{
        height: isSmall ? "100%" : "auto",
        width: isSmall ? "100%" : "100%",
      }}
    >
      <Typography
        className={classes.title}
        style={{
          fontSize: 18,
          fontWeight: 600,
          paddingTop: 30,
          paddingBottom: 10,
        }}
      >
        Sorted Troves
      </Typography>
      <Typography
        className={classes.description}
        style={{
          fontSize: 16,
          fontWeight: 500,
          paddingBottom: 30,
        }}
      >
        Trove Owner
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Box
            display="flex"
            flexDirection="row"
            style={{ width: "100%", paddingLeft: 25 }}
          >
            <Grid item md={6}>
              {[
                "0x7F4a5E7A5F7528cA125620820A4b24084D9e4175",
                "0x4bEae7e69f07299A9cb3cC7183e46D32e10c53c5",
                "0xA6111e55f43B7122E5682c16c339d071Cbf7201A",
                "0xa5f04fD785a5e4897f4136ecD0Ef4dc45723bC8e",
                "0x12B6cc3E2dffa25B642EE73C50732bdD348183DE",
                "0x03ddD5a675e2cfA35b9aE2b207A7dbD95FE24646",
                "0x03ddD5a675e2cfA35b9aE2b207A7dbD95FE24646",
                "0x03ddD5a675e2cfA35b9aE2b207A7dbD95FE24646",
                "0x52d3aBa582A24eeB9c1210D83EC312487815f405",
              ].map((address) => (
                <Typography
                  key={address}
                  className={classes.title}
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginBottom: 10,
                    marginTop: 30,
                  }}
                >
                  {address}
                </Typography>
              ))}
            </Grid>
            <Grid item md={2}>
              {[
                "10,168.96",
                "77,178.32",
                "2,779.13",
                "37,098.76",
                "1,805.98",
                "77,178.32",
                "2,779.13",
                "37,098.76",
                "1,805.98",
              ].map((value) => (
                <Typography
                  key={value}
                  className={classes.title}
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginBottom: 10,
                    marginTop: 30,
                    textAlign: "right",
                  }}
                >
                  {value}
                </Typography>
              ))}
            </Grid>
            <Grid item md={2}>
              {[
                "1.238",
                "1.396",
                "1.749",
                "1.597",
                "1.824",
                "1.396",
                "1.749",
                "1.597",
                "1.824",
              ].map((value) => (
                <Typography
                  key={value}
                  className={classes.title}
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginBottom: 10,
                    marginTop: 30,
                    textAlign: "right",
                  }}
                >
                  {value}
                </Typography>
              ))}
            </Grid>
            <Grid item md={2}>
              {[
                "$12,606.02",
                "$107,441.31",
                "$4,855.51",
                "$59,466.83",
                "$3,295.66",
                "$107,441.31",
                "$4,855.51",
                "$59,466.83",
                "$3,295.66",
              ].map((value) => (
                <Typography
                  key={value}
                  className={classes.title}
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginBottom: 10,
                    marginTop: 30,
                    textAlign: "right",
                  }}
                >
                  {value}
                </Typography>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box>
        {!isSmall && ( // Render the button only on non-small screens
          <Button
            className={classes.navButton}
            style={{
              width: "20%",
              color: "#000000",
              fontWeight: 600,
              borderRadius: 7,
              marginTop: 50,
            }}
            onClick={handleRedeemNow}
          >
            Redeem Now
          </Button>
        )}
        {open && !isSmall && (
          <RedeemDialog open={open} handleClose={handleClose} />
        )}
        {open && isSmall && <RedeemDialog open={open} />}
      </Box>
    </Box>
  );
}
