import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height: "auto",
    width: "100%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  title: {
    fontWeight: 500,
    color: "#757C88",
    textAlign: "left",
    fontSize: 12,
  },
  description: {
    fontWeight: 400,
    color: "#08FCFE",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  field: {
    fontWeight: 400,
    color: "#bdbdbd",
    textAlign: "left",
  },
  value: {
    fontWeight: 600,
    color: "#f9f9f9",
    textAlign: "left",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  infoCard: {
    backgroundColor: "rgba(130, 71, 229, 0.1)",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: "4%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  tokenDescription: {
    fontWeight: 400,
    fontSize: 12,
    color: "#bdbdbd",
    textAlign: "left",
  },
  inputWrapper: {
    border: "1px solid #E2E8F0",
    padding: "3px 6px 3px 6px",
    borderRadius: 5,
    backgroundColor: "rgba(106, 85, 234,0.03)",
    marginTop: 10,
    width: "80%",
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
}));
const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
  color: "white",
});

export default function StrategiesCard() {
  const classes = useStyles();
  const theme = useTheme();
  const [amount, setAmount] = useState(0);
  const [value, setValue] = useState(0);
  const duration = 2000; // dollars
  const [position, setPosition] = useState(0);

  const [scale, setScale] = useState(1);

  const marks = [
    { value: 0, label: "0x" },
    { value: 1, label: "1x" },
    { value: 2, label: "2x" },
    { value: 3, label: "3x" },
    { value: 4, label: "4x" },
    { value: 5, label: "5x" },
    { value: 6, label: "6x" },
  ];

  const handleSliderScale = (event, newValue) => {
    setScale(newValue);
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setAmount((newValue / 100) * duration); // calculate dollar amount based on slider value
  };
  function formatDuration(value) {
    return `$${value}`;
  }

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <>
      <Box pt={0} className={classes.card}>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            display="flex"
            justifyContent="center"
          >
            <Grid item md={12}>
              <Box>
                <Typography
                  className={classes.value}
                  style={{ marginTop: 5, fontSize: 16, fontWeight: 600 }}
                >
                  {" "}
                  Leverage Impact
                </Typography>
                <Typography
                  className={classes.value}
                  style={{ marginTop: 5, fontSize: 16, fontWeight: 600 }}
                >
                  Days Elapsed:
                </Typography>
                <Box className={classes.inputWrapper}>
                  <Input
                    value={amount}
                    onInput={(event) => setAmount(event.target.value)}
                    fullWidth
                    placeholder="0"
                    disableUnderline
                    style={{
                      fontSize: 24,
                      fontWeight: 600,
                      color: "#f9f9f9",
                    }}
                  />
                </Box>
                <Box sx={{ width: "80%" }} className="mt-2">
                  <Typography
                    className={classes.value}
                    style={{ marginTop: 5, fontSize: 14 }}
                  >
                    Initial Trove Value: a$0.00 ($0.00 RAV)
                  </Typography>
                  <Typography
                    className={classes.value}
                    style={{ marginTop: 5, fontSize: 14 }}
                  >
                    Future Trove Value : $0.00 ($0.00 RAV)
                  </Typography>
                  <Typography
                    className={classes.value}
                    style={{ marginTop: 5, fontSize: 14 }}
                  >
                    Leveraged Future Trove Value : $0.00 ($0.00 RAV)
                  </Typography>
                  <Typography
                    className={classes.value}
                    style={{ marginTop: 5, fontSize: 14 }}
                  >
                    Overall Leveraged Trove APY : 0.00%
                  </Typography>
                  <Typography
                    className={classes.value}
                    style={{ marginTop: 5, fontSize: 14 }}
                  >
                    Future Trove ROI : 100.00%
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
