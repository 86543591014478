import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Input,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
    height: "auto",
    width: "100%",
    // border: "2.31855px solid #16FFE1",
    // boxShadow: " 0px 3px 7px 4px #16FFE1",
    borderRadius: 10,

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  navButton: {
    background: `linear-gradient(149.36deg, #FFD22C -7.7%, #C548BB 36.6%, #41ABEC 91.84%)`,
    border: "none",
    borderRadius: "100px",
    boxShadow: "rgba(0, 0, 0, .1) 0 2px 4px 0",
    boxSizing: "border-box",
    color: "#000000",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    fontFamily: "lexend",
    outline: "none",
    padding: "7px 20px",
    textAlign: "center",
    transform: "translateY(0)",
    transition: "transform 150ms, box-shadow 150ms",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "manipulation",
  },
  inputWrapper: {
    border: "1px solid #757C88",
    padding: "3px 10px 3px 10px",
    borderRadius: 5,
    backgroundColor: "rgba(106, 85, 234,0.03)",
    marginTop: 10,
    width: "60%",
  },
}));

export const Profile = ({ open, handleClose }) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(0);

  const handleDialogClose = () => {
    handleClose();
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            background: "linear-gradient(180deg, #2A0E3F 0%, #07030A 100%)",
            borderRadius: 14,
          },
        }}
      >
        <Box className="d-flex justify-content-between p-3">
          <Typography
            style={{ color: "white", fontSize: 18, fontWeight: 600 }}
            className="maxButton"
          >
            oxod8e5cc...4b84
          </Typography>
          <Close
            style={{ color: "white", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        <Divider sx={{ borderBottomWidth: 2 }} color="white" variant="middle" />
        <DialogContent style={{ width: 370 }}>
          <DialogContentText display="flex" justifyContent="space-between">
            <Typography
              style={{ color: "#757C88", fontSize: 15, fontWeight: 500 }}
            >
              Token
            </Typography>
            <Typography
              style={{ color: "#757C88", fontSize: 15, fontWeight: 500 }}
            >
              Balance
            </Typography>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              style={{ color: "white", fontSize: 18, fontWeight: 500 }}
            >
              <img
                src={process.env.PUBLIC_URL + "/Images/pusdIcon.png"}
                style={{ width: 35, height: "auto" }}
              />{" "}
              PUSD
            </Typography>
            <Box display="flex" flexDirection="column">
              <Typography
                style={{ color: "white", fontSize: 18, fontWeight: 500 }}
              >
                0.000
              </Typography>
              <Typography
                style={{ color: "#757C88", fontSize: 18, fontWeight: 500 }}
              >
                0.000
              </Typography>
            </Box>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              style={{ color: "white", fontSize: 18, fontWeight: 500 }}
            >
              <img
                src={process.env.PUBLIC_URL + "/Images/vePGB.png"}
                style={{ width: 35, height: "auto" }}
              />{" "}
              PUSD
            </Typography>
            <Box display="flex" flexDirection="column">
              <Typography
                style={{ color: "white", fontSize: 18, fontWeight: 500 }}
              >
                0.000
              </Typography>
              <Typography
                style={{ color: "#757C88", fontSize: 18, fontWeight: 500 }}
              >
                0.000
              </Typography>
            </Box>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              style={{ color: "white", fontSize: 18, fontWeight: 500 }}
            >
              <img
                src={process.env.PUBLIC_URL + "/Images/PGB.png"}
                style={{ width: 35, height: "auto" }}
              />{" "}
              PGB
            </Typography>
            <Box display="flex" flexDirection="column">
              <Typography
                style={{ color: "white", fontSize: 18, fontWeight: 500 }}
              >
                0.000
              </Typography>
              <Typography
                style={{ color: "#757C88", fontSize: 18, fontWeight: 500 }}
              >
                0.000
              </Typography>
            </Box>
          </DialogContentText>

          <Divider
            className="mt-2"
            sx={{ borderBottomWidth: 2, width: "100%" }}
            color="white"
          />
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              style={{ color: "#757C88", fontSize: 15, fontWeight: 600 }}
            >
              View on explorer
            </Typography>
            <Typography>
              <img
                src={process.env.PUBLIC_URL + "/Images/clipboardexport.png"}
                style={{ width: 20, height: "auto" }}
              />{" "}
            </Typography>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              style={{ color: "#757C88", fontSize: 15, fontWeight: 600 }}
            >
              Copy Address
            </Typography>
            <Typography>
              <img
                src={process.env.PUBLIC_URL + "/Images/copy.png"}
                style={{ width: 20, height: "auto" }}
              />{" "}
            </Typography>
          </DialogContentText>
          <DialogContentText
            className="mt-4"
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              style={{ color: "#757C88", fontSize: 15, fontWeight: 600 }}
            >
              Transactions
            </Typography>
            <Typography>
              <img
                src={process.env.PUBLIC_URL + "/Images/arrowright2.png"}
                style={{ width: 20, height: "auto" }}
              />{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Profile;
